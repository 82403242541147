/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, Fragment } from "react";
import Layout from "../../../../Layout/Layout";
import BodyContainer from "../../../../UI/BodyArea/Body";
import { connect } from "react-redux";
import styles from "./OfferCategoriesCard.module.scss";
import { useHistory } from "react-router-dom";
import "../../../../../Common.scss";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import percentageIcon from "../../../../../Resources/offers/percentage_icon.svg";
import transationEmptyIcon from "../../../../../Resources/transfer/transaction_empty.svg";
import searchIcon from "../../../../../Resources/shared/search_icon.svg";

const OfferCategories = (props) => {

  let history = useHistory();

  const handleProviderClick = (event, index) => {
    sessionStorage.setItem("CATEGORY-INDEX", index.toString());
    history.push('/offers');
  }

  useEffect(() => {

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    window.scrollTo(0, 0);

    sessionStorage.setItem('OFFER_SEARCH_NAVIGATION', 'OFFER_CATEGORIES');

    return () => {
      
      window.removeEventListener('popstate', onBackButtonEvent);
    }

  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    history.push(handleBackClick())
  }

  const handleBackClick = () => {
    switch (sessionStorage.getItem("STAR-TIER-OFFERS-PATH")) {
      case "HOME":
        return "/"
      case "STAR-LOYALTY":
        return "/star-loyalty"
      case "STAR-LOYALTY-INFO":
        return "/star-loyalty-info"
      default:
        return "/"
    }

  }

  return (
    <Layout
      leftIcon={ArrowLeftIcon}
      rightIcon={searchIcon}
      headerTitle="Offers"
      leftIconLink={handleBackClick()}
      rightIconLink="/offer-search"
    >
      <BodyContainer>
        <Fragment>
          {
            (props.cxOffers.STATUS !== 'FAILED' && props.cxOffers.DATA.carecategory_list != null && props.cxOffers.DATA.carecategory_list.length > 0) ?
              <div className="listing-container">
                {props.cxOffers.DATA.carecategory_list.map((offer, index) => {
                  return (

                    <div key={index} className={styles.card}>
                      <a onClick={(e) => handleProviderClick(e, index)}>
                        <div className={styles.card_thumb}>
                          <img
                            src={offer.category_image_url}
                            alt="Logo"
                            className={styles.card_thumb}
                          />
                        </div>

                        <div className={styles.card_content}>
                          <div className="col-12">
                            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 'medium' }}>
                              <img src={percentageIcon}
                                style={{
                                  width: '11px',
                                  height: '11px',
                                  marginRight: '6px'
                                }} />
                              <div className={styles.card_content__title}>{offer.offer_list.length} Offers Available</div>
                            </div>
                          </div>

                        </div>
                      </a>
                    </div>
                  );
                })}
              </div> : ""
          }
          {
            (props.cxOffers.STATUS === 'FAILED' || props.cxOffers.DATA.carecategory_list == null || props.cxOffers.DATA.carecategory_list.length === 0) ?
              <div className="no-data-container">
                <img src={transationEmptyIcon} alt="Logo" />
                <div className="empty-heading">
                  Sorry, No offers available
                </div>
              </div> : ""
          }
        </Fragment>
      </BodyContainer>

    </Layout>
  );
};

const mapStateToProps = (response) => {
  return {
    cxOffers: response.OffersReducer.cxOffers,
  };
};

export default connect(mapStateToProps)(OfferCategories);
