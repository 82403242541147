import React, { useEffect, useState } from 'react';
import Layout from '../../../../Layout/Layout';
import { useHistory } from "react-router-dom";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import starPoints from "../../../../../Resources/shared/starpointsLogo.png";
import Dialog from "../../../../../Resources/transfer/dialog_mobile.svg";
import Footer from '../../../../UI/FooterArea/Footer';
import BodyContainer from '../../../../UI/BodyArea/Body';
import { connect } from "react-redux";
import firebase from 'firebase/app';
import "./../../../../../Common.scss";

const SelfRedemption = (props) => {
    const analytics = firebase.analytics();
    const [redeemablePoints, setRedeemablePoints] = useState("0");
    const [redeemablePointsDecimal, setRedeemablePointsDecimal] = useState("00");
    const [pointAmount, setPointAmount] = useState(0.0);
    const [redeemableAmountFloat, setRedeemableAmountFloat] = useState(0);
    let history = useHistory();

    let redeem = (event) => {

        if (pointAmount && pointAmount < redeemableAmountFloat) {
            analytics.logEvent('self_redemption_btn_click', { redeemAmount: pointAmount });
            sessionStorage.setItem("transacrionType", "SELF-REDEMPTION");
            history.push('/otp-verification');
        }

    }

    const splitDecmial = (value) => {
        let numArray = value.toString().split(".");
        return numArray;
    };

    const numberWithCommas = (x) => {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }

    useEffect(() => {

        if (props.starPoints) {
            if (props.starPoints.DATA && props.starPoints.DATA.balance) {
                let numArray = splitDecmial(props.starPoints.DATA.balance.redeemableBalance);
                setRedeemablePoints((numArray[0]));

                setRedeemablePointsDecimal((numArray[1]) ? (numArray[1]).length === 1 ? (numArray[1]) + "0" : (numArray[1]) : "00");

                setPointAmount(sessionStorage.getItem("pointsAmount"));

                //setting for common usage here
                let redeemablePointsFloat = parseFloat(props.starPoints.DATA.balance.redeemableBalance);
                setRedeemableAmountFloat(redeemablePointsFloat);
            }
        }

    }, [props.starPoints]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/self-redemption")
    }

    const getNameByLob = () => {

        switch (sessionStorage.getItem('subscriberType')) {
            case "MOBILE":
                return "Dialog Mobile"
            case "HBB":
                return "Dialog HBB"
            case "DTV":
                return "Dialog TV"
            default:
                return "Dialog Mobile"
        }

    }

    return (
        <div>
            <Layout
                leftIcon={ArrowLeftIcon}
                rightIcon={Dialog}
                headerTitle="Confirmation"
                leftIconLink="/self-redemption"
            >
                <BodyContainer>
                    <header className="App-container">
                        <div style={{ overflow: "hidden", width: "100%", backgroundColor: "#ffffff" }}>
                            <div className="Points-display" style={{ paddingTop: '25%' }}>
                                <div style={{ fontSize: '14px', fontWeight: 'medium', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }} >
                                    Star Points
                                </div>
                                <div className="row" style={{ justifyContent: 'center', marginTop: '-0.5em' }}>
                                    <h4 className="color-black" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }}>
                                        <span className="font-bold-1 font-size-20">{pointAmount}</span>
                                    </h4>
                                </div>

                                <div className="row" style={{ fontSize: '15px', marginTop: "-70px", fontWeight: 'bold', display: 'flex', justifyContent: 'center', lineHeight: '16sp' }}>
                                    {isNaN(pointAmount) ?
                                        <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Please enter value</div> : ""}
                                    {pointAmount > redeemableAmountFloat ?
                                        <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Insufficient points balance</div> : ""}
                                </div>
                            </div>

                        </div>
                        <div className="col">
                            <hr></hr>
                            <h4 className="color-gray" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }}>
                                <span className="font-bold-2 font-size-12">To</span>
                            </h4>
                            <h4 className="color-black" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }}>
                                <span className="font-regular font-size-16">{getNameByLob()}</span>
                            </h4>
                            <hr></hr>
                            <h4 className="color-gray" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }}>
                                <span className="font-bold-2 font-size-12">Connection number</span>
                            </h4>
                            <h4 className="color-black" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }}>
                                <span className="font-regular font-size-16">{sessionStorage.getItem("mobileNumber")}</span>
                            </h4>
                            <hr></hr>
                        </div>
                    </header>
                </BodyContainer>
            </Layout>
            <Footer>
                <div className="row" style={{ position: 'sticky', bottom: 0, left: 0, right: 0 }}>
                    <div className="col-12">
                        <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 'medium' }}>
                            <img src={starPoints}
                                style={{
                                    backgroundColor: 'white',
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '10px',
                                    fontFamily: 'OpenSans'
                                }} />
                            Redeemable Points : {numberWithCommas(redeemablePoints)}.
                            <span style={{ fontSize: '14px' }}>{redeemablePointsDecimal}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <a onClick={(e) => redeem(e)} style={{ color: 'white' }}>
                            <button className="btn-transaction remove-outline">
                                NEXT
                            </button>
                        </a>
                    </div>
                </div>

            </Footer>
        </div>
    );
}

const mapStateToProps = (response) => {
    return {
        starPoints: response.StarPointReducer.starPoints,
    };
};

export default connect(mapStateToProps)(SelfRedemption);
