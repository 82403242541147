import ApiClient from "./ApiClient";

export const fetchTransactionsService = async () => {
 
  let ENDPOINT = `core/subscriber/transactions`;
  
  let params = {
    lob: sessionStorage.getItem('subscriberType')
  }
  
  let api = await ApiClient.get(
    ENDPOINT,
    params,
    (response) => {
      console.log("****** Get transactions response *******", response);
      return response
    }
  );
  return api;

};
