import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import firebase from 'firebase/app';
import threeStarLogo from "../../../../Resources/home/three_star_logo.svg";
import fiveStarLogo from "../../../../Resources/home/five_star_logo.svg";
import sevenStarLogo from "../../../../Resources/home/seven_star_logo.svg";
import starTierOffer from "../../../../Resources/starLoyalty/loyalty_offers.svg";
import learnLoyalty from "../../../../Resources/starLoyalty/star_gray.svg";
import userIcon from "../../../../Resources/shared/user_icon.svg";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import ArrowRightIcon from "../../../../Resources/shared/arrow_right_purple.svg";
import Layout from "../../../Layout/Layout";
import "./StarLoyalty.scss";
import "./../../../../Common.scss";
import BodyContainer from "../../../UI/BodyArea/Body";

const StarLoyalty = (props) => {

    const analytics = firebase.analytics();

    const [tierPoints, setTierPoints] = useState("0");
    const [lastTierPointsEarnedDate, setLastTierPointsEarnedDate] = useState("0");
    const [tierLevelIcon, setTierLevelIcon] = useState(null);
    const [achievablePoints, setAchievablePoints] = useState("");
    const [archievedProgress, setArchievedProgress] = useState("");
    const [tierLevel, setTierLevel] = useState("");
    const [nextTierLevel, setNextTierLevel] = useState("");
    const [showTierLevel, setShowTierLevel] = useState(false);
    const [previousYear, setPreviousYear] = useState(0);
    const [loyaltyUpgrateMessage, setLoyaltyUpgrateMessage] = useState("");
    const [isStarRewardsOffersAvailable, setIsStarRewardsOffersAvailable] = useState(true);

    let history = useHistory();

    const numberWithCommas = (x) => {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }

    const handleTierOffersClicked = () => {
        analytics.logEvent('star_loyalty_tier_offers_btn_click')
        sessionStorage.setItem("STAR-TIER-OFFERS-PATH", "STAR-LOYALTY");
        history.push("/offer-categories")
    }

    const handleLeardMoreClicked = () => {
        analytics.logEvent('star_loyalty_learn_more_btn_click')
        history.push('/star-loyalty-info')
    }

    const handleTermsAndConditionsClicked = () => {
        history.push({
            pathname: '/star-loyalty-info',
            state: { showTerms: "true" }
        })
    }

    useEffect(() => {

        let tierPoints = parseFloat(props.tierPoints.DATA.tierPoints)
        setTierPoints(tierPoints)
        setLastTierPointsEarnedDate(props.tierPoints.DATA.lastUpdatedDate ? props.tierPoints.DATA.lastUpdatedDate : new Date());

        let loyaltyType = props.tierPoints.DATA.loyaltyType;

        if (loyaltyType === process.env.REACT_APP_LOYALTY_TYPE_NOT_DEFINED) {

            setIsStarRewardsOffersAvailable(false);

            for (let i = 0; i < props.tierPoints.DATA.loyaltyTierRanges.length; i++) {
                let range = props.tierPoints.DATA.loyaltyTierRanges[i];

                if (range.loyaltyType === process.env.REACT_APP_THREE_STAR) {

                    let loyaltyFloorValue = parseFloat(range.loyaltyFloorValue)
                    setAchievablePoints(loyaltyFloorValue);
                    setArchievedProgress(((tierPoints / loyaltyFloorValue) * 100) + "%")
                    setNextTierLevel(range.displayName)
                    setLoyaltyUpgrateMessage("You will be upgraded to " + range.displayName + " category following a quarterly loyalty evaluation and upon accumulating " + numberWithCommas(loyaltyFloorValue) + " Points. Please refer ");
                    return;
                }
            }

        } else {

            let loyaltyCellingValue = parseFloat(props.tierPoints.DATA.loyaltyCellingValue) + 1;
            setAchievablePoints(loyaltyCellingValue)
            setArchievedProgress(((tierPoints / loyaltyCellingValue) * 100) + "%")
            setNextTierLevel(props.tierPoints.DATA.nextTier)
            setLoyaltyUpgrateMessage("You will be upgraded to " + props.tierPoints.DATA.nextTier + " category following an annual loyalty evaluation and upon accumulating " + numberWithCommas(loyaltyCellingValue) + " Points. Please refer ");

        }

        switch (loyaltyType) {

            case process.env.REACT_APP_THREE_STAR: case process.env.REACT_APP_THREE_STAR_S:
                setTierLevelIcon(threeStarLogo);
                break;
            case process.env.REACT_APP_FIVE_STAR: case process.env.REACT_APP_FIVE_STAR_S:
                setTierLevelIcon(fiveStarLogo);
                break;
            case process.env.REACT_APP_SEVEN_STAR: case process.env.REACT_APP_SEVEN_STAR_S:
                setTierLevelIcon(sevenStarLogo);
                break;
            default:
                return;
        }

        setTierLevel(props.tierPoints.DATA.displayName)

        let currentYear = new Date().getFullYear();
        let previousYear = currentYear - 1;
        setPreviousYear(previousYear);

        setShowTierLevel(true);

        if (loyaltyType === process.env.REACT_APP_LOYALTY_TYPE_NOT_DEFINED || !props.cxOffers.STATUS) {
            setIsStarRewardsOffersAvailable(false);
        }

    }, [props.tierPoints]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/")
    }

    return (
        <Fragment>
            <Layout
                leftIcon={ArrowLeftIcon}
                headerTitle="Dialog Star Rewards"
                leftIconLink="/"
            >

                <BodyContainer>
                    <header className="App-container">
                        <div style={{ width: "100%" }}>

                            <div className="row no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px' }}>
                                <div className="col-1 no-padding">
                                    <div className="img-submit align-left">
                                        <img src={userIcon} style={{ width: '36px', height: '36px' }} />
                                    </div>
                                </div>
                                <div className="col-10 no-padding">
                                    <div className="color-gray align-left" style={{ fontSize: '15px', fontWeight: 'bold', display: 'flex', maxLines: '1', marginLeft: '10px' }} >
                                        {sessionStorage.getItem("mobileNumber")}
                                    </div>
                                </div>

                            </div>

                            {
                                showTierLevel ?
                                    <div className="card card-3" style={{ backgroundColor: '#afdc7e' }}>
                                        <div className="row no-padding">
                                            <div className="col-3 no-padding">
                                                <img src={tierLevelIcon} className="App-list-icon align" alt="logo" />
                                            </div>
                                            <div className="col-6 no-padding">
                                                <div className="row no-padding">
                                                    <h2 className="color-black font-size-14">{tierLevel} Member</h2>
                                                </div>
                                                <div className="row no-padding">
                                                    <h4 className="color-black font-size-12">
                                                        (Based on {previousYear} Tier Points)</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                            <div className="card card-3">
                                <div className="App-container" style={{ marginLeft: '15px', marginRight: '15px' }}>
                                    <div style={{ fontSize: '14px', fontWeight: '700', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }} >
                                        Your Tier Points
                                    </div>

                                    <div style={{ fontSize: '40px', fontWeight: '500', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px' }} >
                                        {numberWithCommas(tierPoints)}
                                    </div>

                                    <div style={{ fontSize: '12px', fontWeight: '500', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-5px' }} >
                                        earned as of {moment(lastTierPointsEarnedDate).format("DD MMMM YYYY")}
                                    </div>

                                    <div className="progress-bar" style={{ backgroundColor: '#868686', display: 'flex', marginTop: '15px' }}>
                                        <span className="bar">
                                            <span className="progress" style={{ backgroundColor: '#afdc7e', width: archievedProgress }}></span>
                                        </span>
                                    </div>

                                    <div className="row no-padding" style={{ justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                        <div style={{ fontSize: '12px', fontWeight: '700', textAlign: 'center' }} >
                                            {loyaltyUpgrateMessage}
                                            <span style={{ color: "#2598ed", fontSize: '12px', fontWeight: '800', textAlign: 'center' }}>
                                                <a onClick={handleTermsAndConditionsClicked} > T&C</a>
                                            </span>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            {isStarRewardsOffersAvailable ? <a style={{ color: 'white' }} onClick={handleTierOffersClicked}>
                                <div className="card card-3 ">
                                    <div className="row no-padding">
                                        <div className="col-3 no-padding">
                                            <img src={starTierOffer} className="App-list-icon" alt="logo" />
                                        </div>
                                        <div className="col-6">
                                            <div className="row no-padding" style={{ marginLeft: '-30px' }}>
                                                <h2 className={"color-black"} style={{ fontSize: '14px' }}>
                                                    Star Reward offers
                                                </h2>
                                            </div>
                                            <div className="row no-padding"></div>
                                        </div>
                                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                                        </div>
                                    </div>
                                </div>
                            </a> : null}

                            {/*<Link to="/Star-loyalty-learn">*/}
                            <a style={{ color: 'white' }} onClick={handleLeardMoreClicked}>
                                <div className="card card-3 ">
                                    <div className="row no-padding">
                                        <div className="col-3 no-padding">
                                            <img src={learnLoyalty} className="App-list-icon" alt="logo" />
                                        </div>
                                        <div className="col-6">
                                            <div className="row no-padding" style={{ marginLeft: '-30px' }}>
                                                <h2 className={"color-black"} style={{ fontSize: '14px' }}>
                                                    Star Rewards info
                                                </h2>
                                            </div>
                                            <div className="row no-padding"></div>
                                        </div>
                                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                                        </div>
                                    </div>
                                </div>
                            </a>
                            {/*</Link>*/}


                            <div style={{ height: '85px' }}></div>

                        </div>

                    </header>
                </BodyContainer>
            </Layout>
        </Fragment>
    );
};

const mapStateToProps = (response) => {
    return {
        tierPoints: response.TierPointsReducer.tierPoints,
        cxOffers: response.OffersReducer.cxOffers
    };
};

export default connect(mapStateToProps)(StarLoyalty);