import React, { useEffect, useState, Fragment } from 'react';
import Layout from '../../../Layout/Layout';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import Footer from '../../../UI/FooterArea/Footer';
import BodyContainer from '../../../UI/BodyArea/Body';
import "./../../../../Common.scss";
import "./OTPVerification.css";
import errorImg from "../../../../Resources/shared/error.svg";
import Modal from "react-modal";
import CircularLoading from "../../../Common/CirculerLoading";
import disableScroll from 'disable-scroll';
import {
    requestOTPAction,
    makeDonationAction,
    makeSelfRedemptionAction,
    makeTransferPointsAction
} from "../../../../Actions/authTransactionAction";

const SelfRedemption = (props) => {

    const [isLoading, setisLoading] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [isInvalidOTP, setIsInvalidOTP] = useState(false);
    const [isResendPinDisabled, setIsResendPinDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [pointAmount, setPointAmount] = useState(0.0);
    const [merchantAlias, setMerchantAlias] = useState("");
    const [isSelfRemptionSubmitted, setIsSelfRemptionSubmitted] = useState(false);
    const [isDonationSubmitted, setIsDonationSubmitted] = useState(false);
    const [isPointsTransferSubmitted, setIsPointsTransferSubmitted] = useState(false);

    let history = useHistory();
    let resetTimeOut = 30;
    let otpMax = 6;
    var verficationCode = "";

    const handleOpenModal = () => {
        setShowModel(false);
    }
    const handleCloseModal = () => {
        setShowModel(false);
    }
    const afterOpenModal = () => {
    }

    const customStyles = {
        overlay: {
            zIndex: '30000',
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        content: {
            width: '90%',
            top: 'auto',
            left: '5%',
            right: '5%',
            bottom: '30%',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '20px',
            color: '#000000)',
            padding: '10px',
            textAlign: 'center'
        }
    };

    const loadingStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    };

    const handleBackClick = () => {

        switch (sessionStorage.getItem("transacrionType")) {
            case "SELF-REDEMPTION":
                return "/self-redemption-confirmation"
            case "DONATION":
                return "/points-amount"
            case "TRANSFER-POINTS":
                return "/transfer-points-confirmation"
            default:
                return "/"
        }

    }

    const initiateCountTimer = () => {

        let countDown = 0;
        const interval = setInterval(() => {
            countDown += 1;
            if (countDown === resetTimeOut) {
                setIsResendPinDisabled(false);
                clearInterval(interval);
            }
        }, 1000);
    }

    const resendButtonClicked = () => {
        if (!isResendPinDisabled) {
            setIsResendPinDisabled(true);
            initiateCountTimer();
            props.requestOTPAction();
        }
    }

    const onPointValueChange = (event) => {
        setIsInvalidOTP(false);

        let value = event.target.value;

        if (value.length >= otpMax) {
            setisLoading(true);
            verficationCode = value;
            switch (sessionStorage.getItem("transacrionType")) {
                case "SELF-REDEMPTION":
                    performSelfRedemption();
                    break;
                case "DONATION":
                    performDontation();
                    break;
                case "TRANSFER-POINTS":
                    performPointsTransfer();
                    break;
                default:
                    break;
            }
        }

    }

    const performDontation = () => {
        setIsDonationSubmitted(true);
        props.makeDonationAction({
            subscriberAuth: verficationCode,
            amount: pointAmount,
            donationCounterAlias: merchantAlias,
            subscriberType: sessionStorage.getItem('subscriberType')
        });

    }

    const performSelfRedemption = () => {
        setIsSelfRemptionSubmitted(true);
        props.makeSelfRedemptionAction({
            subscriberAuth: verficationCode,
            amount: pointAmount,
            subscriberType: sessionStorage.getItem('subscriberType')
        });
    }

    const performPointsTransfer = () => {
        setIsPointsTransferSubmitted(true);
        props.makeTransferPointsAction({
            toNumber: sessionStorage.getItem("connectionReference"),
            subscriberAuth: verficationCode,
            amount: pointAmount,
            fromSubscriberType: sessionStorage.getItem('subscriberType'),
            toSubscriberType: getSubscriberTypeByLob()
        });
    }

    const getSubscriberTypeByLob = () => {
       
        let lob = sessionStorage.getItem('lob');
        let connectionNumber = sessionStorage.getItem("connectionReference");
        
        switch (lob) {
            case 'GSM':
                return 'MOBILE';
            case 'HBB':
                return 'HBB';
            case 'DTV':
                return 'DTV';
            default:
                if (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
                    return 'BAR_CODE';
                } else {
                    return 'MOBILE';
                }
        }
    }


    useEffect(() => {
    }, [isInvalidOTP]);

    useEffect(() => {

        const donateMerchantAlias = sessionStorage.getItem("donateMerchantAlias");
        setMerchantAlias(donateMerchantAlias);

        const pointsAmount = sessionStorage.getItem("pointsAmount");
        setPointAmount(pointsAmount);

        props.requestOTPAction();

        disableScroll.on();

        return () => {
            disableScroll.off();
        };

    }, []);

    useEffect(() => {

        Modal.setAppElement('body');
        if (isSelfRemptionSubmitted && props.redemption && props.redemption.STATUS) {
            setisLoading(false);
            setIsSelfRemptionSubmitted(false);
            if (props.redemption.STATUS === "SUCCESS") {
                history.push('/self-redemption-success');
            } else {

                let statusCode = props.redemption.DATA.statusCode;

                if (statusCode === 112) {
                    setIsInvalidOTP(true);
                } else if (statusCode === 255) {
                    setErrorMessage("Star Points redemption declined. The connection status is Permanently disconnected");
                    setShowModel(true);
                } else {
                    setErrorMessage("Sorry! We encounterd an error while processing your payment");
                    setShowModel(true);
                }


            }
        }
    }, [props.redemption]);

    useEffect(() => {

        Modal.setAppElement('body');
        if (isDonationSubmitted && props.transaction && props.transaction.STATUS) {
            setIsDonationSubmitted(false);
            setisLoading(false);
            if (props.transaction.STATUS === "SUCCESS") {
                history.push('/donate-submit');
            } else {

                let statusCode = props.transaction.DATA.statusCode;

                if (statusCode === 112) {
                    setIsInvalidOTP(true);
                } else if (statusCode === 255) {
                    setErrorMessage("Star Points donation declined. The connection status is Permanently disconnected");
                    setShowModel(true);
                } else {
                    setErrorMessage("Sorry! We encounterd an error while processing your transaction");
                    setShowModel(true);
                }
            }
        }
    }, [props.transaction]);

    useEffect(() => {

        Modal.setAppElement('body');
        if (isPointsTransferSubmitted && props.transferPoints && props.transferPoints.STATUS) {
            setisLoading(false);
            setIsSelfRemptionSubmitted(false);
            console.log("points transfer", props.transferPoints)
            if (props.transferPoints.STATUS === "SUCCESS") {
                history.push('/transfer-points-success');
            } else {

                let statusCode = props.transferPoints.DATA.statusCode;

                if (statusCode === 112 || statusCode === 1030) {
                    setIsInvalidOTP(true);
                } else if (statusCode === 255) {
                    setErrorMessage("Star Points transfer request declined. The connection status is Permanently disconnected");
                    setShowModel(true);
                } else {
                    setErrorMessage("Sorry! We encounterd an error while processing your transfer");
                    setShowModel(true);
                }
            }
        }
    }, [props.transferPoints]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push(handleBackClick())
    }

    return (
        isLoading ? (
            <div style={loadingStyles}>
                <CircularLoading />
            </div>
        ) :
            (
                <Fragment>
                    <Layout
                        leftIcon={ArrowLeftIcon}
                        headerTitle="Verification"
                        leftIconLink={handleBackClick}
                    >
                        <BodyContainer style={{ overflow: "hidden" }}>

                            <div style={{ overflow: "hidden", width: "100%", backgroundColor: "#ffffff" }}>
                                <div className="Points-display" style={{ paddingTop: '10%' }}>

                                    <div style={{ color: "#333433", fontSize: '16px', fontWeight: '400', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }} >
                                        We have sent you a 6 digit OTP to verify</div>
                                    <div style={{ color: "#333433", fontSize: '16px', fontWeight: '400', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }} >
                                        your transaction</div>

                                    <div style={{ color: "#333433", fontSize: '16px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp', marginTop: '15px' }} >
                                        +94 {sessionStorage.getItem("otpReceipient")}</div>

                                    <div >
                                        <input
                                            className="otp-input"
                                            type="number"
                                            inputMode="numeric"
                                            name="otp-input"
                                            onChange={(e) => onPointValueChange(e)}
                                            autoFocus="{true}?"
                                        />
                                    </div>

                                    <div className="row" style={{ fontSize: '15px', marginTop: "-10px", fontWeight: 'bold', display: 'flex', justifyContent: 'center', lineHeight: '16sp' }}>
                                        {isInvalidOTP ?
                                            <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Invalid OTP</div> : ""}
                                    </div>


                                </div>
                                <div className="justify-content-center" >
                                    <p className="resendButton" style={{ color: isResendPinDisabled ? "#cccccc" : "#2598ed" }} onClick={resendButtonClicked}>
                                        RESEND PIN
                                    </p>
                                </div>

                            </div>


                        </BodyContainer>
                    </Layout>
                    <Footer>
                        <div>
                            <Modal
                                isOpen={showModel}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={handleCloseModal}
                                style={customStyles}
                                contentLabel="Error Modal"
                            >
                                <div className="container content">
                                    <div className="popUp">
                                        <div className="row justify-content-center">
                                            <img src={errorImg} alt="Logo" />
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="justify-content-center" style={{ width: '65%' }}>
                                                <h6 className="errorModalMainText">
                                                    Transaction failed
                                                </h6>
                                            </div>

                                        </div>
                                        <div className="row justify-content-center" style={{ marginTop: '-2.3em' }}>
                                            <div className="justify-content-center" style={{ width: '100%' }}>
                                                <h6 className="errorModalSubText">
                                                    {errorMessage}
                                                </h6>
                                            </div>

                                        </div>
                                        <div className="justify-content-center" style={{ borderTop: '2px solid #eceef4', marginLeft: '-2em', marginRight: '-2em' }}>
                                            <p className="popUpActionText" onClick={handleOpenModal}>
                                                CLOSE
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>

                    </Footer>
                </Fragment>)
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestOTPAction: () => dispatch(requestOTPAction()),
        makeSelfRedemptionAction: (requestBody) => dispatch(makeSelfRedemptionAction(requestBody)),
        makeDonationAction: (requestBody) => dispatch(makeDonationAction(requestBody)),
        makeTransferPointsAction: (requestBody) => dispatch(makeTransferPointsAction(requestBody)),
    };
};

const mapStateToProps = (response) => {
    return {
        otp: response.AuthTransactionReducer.otp,
        transaction: response.AuthTransactionReducer.transactions,
        redemption: response.AuthTransactionReducer.redemption,
        transferPoints: response.AuthTransactionReducer.transferPoints,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfRedemption);
