import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import StarPointsTransfer from './Pages/StarPointsTransfer';

const StarPointsTransferRoute = () => {
    return (
        <Fragment>
            <Route path="/star-points-transfer" component={StarPointsTransfer} />
        </Fragment>
    )
}

export default withRouter(StarPointsTransferRoute)