import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import StarLoyalty from './Pages/StarLoyalty';

const StarLoyaltyRoute = () => {
    return (
        <Fragment>
            <Route path="/star-loyalty" component={StarLoyalty} /> 
        </Fragment>
    )
}

export default withRouter(StarLoyaltyRoute)