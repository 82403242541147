import React from "react";
import styles from "./StarPointsDetailsOverlay.module.scss";
import moment from "moment";
import xImage from "../../../../Resources/shared/close_icon.svg";

const StarPointsDetailsOverlay = (props) => {
  const details = props.transactionsDetails;
  const notApplicableString = "N/A";
  console.log("Details", details);
  const close = (value) => {
    props.handleCLose(value);
  };

  // const getKey = () => {
  //   return 1;
  // };

  return (
    <div className={styles.overlay}>
      <div className={styles.overlay_card}>
        <div className={styles.overlay_card_content}>
          <div className={styles.overlay_card_content_header}>
            <div className={styles.overlay_card_content_header_left}>
              Transaction details
            </div>
            <div
              className={styles.overlay_card_content_header_right}
              onClick={close.bind(close, false)}
            >
              {/*&#10006;*/}
              <img
                  src={xImage}
                  className=""
                  alt="logo"
              />
            </div>
          </div>
          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Amount
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value_amount}>
              {/*<span>LKR</span>*/}
              <span> {details.amount ? details.amount : notApplicableString}</span>
              <span> {details.amount ? "Point(s)" : notApplicableString}</span>
            </div>
          </div>
          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Merchant
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              {details.merchant ? details.merchant : notApplicableString }
            </div>
          </div>
          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Connection number
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              {/*<div>Star points</div>*/}
              <div
                className={styles.overlay_card_content_details__value_mobile}
              >
                {details.mobile ? details.mobile.replace(/(\d{3})\d{3}/, "$1***") : notApplicableString}
              </div>
            </div>
          </div>

          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Transaction Type
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              {details.transactionTypeText ? details.transactionTypeText : notApplicableString}
            </div>
          </div>

          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Transaction ID
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              {details.transactionSerial ? details.transactionSerial : notApplicableString}
            </div>
          </div>

          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Bill Number
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              {details.billNumber ? details.billNumber : notApplicableString}
            </div>
          </div>

          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Date & Time
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              <div>{details.txDate ? moment(details.txDate, 'DD/MM/YYYY').format('ll') : notApplicableString}</div>
              <div>{details.txDate ? moment(details.txDate, 'DD/MM/YYYY h:mm:ss').format('LT') : notApplicableString}</div>
            </div>
          </div>

          <div className={styles.overlay_card_content_details}>
            <div className={styles.overlay_card_content_details__Proprty}>
              Status
            </div>
            <div className={styles.overlay_card_content_details__seperator}>
              :
            </div>
            <div className={styles.overlay_card_content_details__value}>
              Successful
            </div>
          </div>
          <div className={styles.overlay_card_content_details}></div>
        </div>
      </div>
    </div>
  );
};

export default StarPointsDetailsOverlay;
