import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import Offers from './Pages/Offers.js';

const OffersRoute = () => {
    return (
        <Fragment>
            <Route path="/offers" component={Offers} />
        </Fragment>
    )
}

export default withRouter(OffersRoute)
