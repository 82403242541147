import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import OfferDetail from './Pages/OfferDetail';

const OfferDetailRoute = () => {
    return (
        <Fragment>
            <Route path="/offer-detail" component={OfferDetail} />
        </Fragment>
    )
}

export default withRouter(OfferDetailRoute)
