/* eslint-disable import/no-anonymous-default-export */
import * as types from '../Actions';

const initailState = {
    organizations: []
};

export default function (state = initailState, action) {
    let response = action.response;
    
    switch (action.type) {
        // Fetch donate organizations
        case types.FETCH_DONATION_ORGANIZATIONS_SUCCESS:
            return { ...state, donations: response };
        case types.FETCH_DONATION_ORGANIZATIONS_FAIL:
            return { ...state, donations: response };
        default:
            return state;
    }
}