import { put, call } from "redux-saga/effects";

import * as types from "../Actions";
import {
  requestOTPService,
  makeSelfRedemption,
  makeDonation,
  makeTransferPoints
} from "../Services/authTransactionService";

export function* requestOTPSaga() {
  
  try {
    
    let response = yield call(requestOTPService);
   
    if (response && response.success) {
      yield put({ type: types.REQUEST_OTP_SUCCESS, response });
    } else {
      yield put({ type: types.REQUEST_OTP_FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.REQUEST_OTP_FAIL, response: error });
  }
}

export function* selfRedemptionSaga(requestBody) {
 
  try {
    
    let response = yield call(makeSelfRedemption, requestBody);
    
    if (response && response.success) {
      yield put({ type: types.MAKE_SELF_REDEMPTION_SUCCESS, response });
    } else {
      yield put({ type: types.MAKE_SELF_REDEMPTION__FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.MAKE_SELF_REDEMPTION__FAIL, response: error });
  }
}

export function* makeDonateSaga(requestBody) {
  
  try {
    
    let response = yield call(makeDonation, requestBody);
    
    if (response && response.success) {
      yield put({ type: types.FETCH_ORGANIZATIONS_DONATE_SUCCESS, response });
    } else {
      yield put({ type: types.FETCH_ORGANIZATIONS_DONATE_FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.FETCH_ORGANIZATIONS_DONATE_FAIL, response: error });
  }
}

export function* makeTransferPointsSaga(requestBody) {
  
  try {
    
    let response = yield call(makeTransferPoints, requestBody);
   
    if (response && response.success) {
      yield put({ type: types.MAKE_TRASFER_POINTS_SUCCESS, response });
    } else {
      yield put({ type: types.MAKE_TRASFER_POINTS_FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.MAKE_TRASFER_POINTS_FAIL, response: error });
  }
}
