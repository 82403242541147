import React, {useState, Fragment, useEffect} from "react";
import { connect } from "react-redux";
import MessageTemplate from "../../../UI/MessagePopups/Pages/MessageTemplate";
import closeIcon from "../../../../Resources/shared/close_icon.svg";
import emptyBox from "../../../../Resources/negativeScenario/box_image.svg";
import somethingWentWrong from "../../../../Resources/negativeScenario/something_went_wrong.svg";
import BlockedIcon from "../../../../Resources/negativeScenario/blocked.svg";
import firebase from 'firebase/app';

const NegativeUserSenario = (props) => {

    const analytics = firebase.analytics();

    const [headerContentMessage, setHeaderContentMessage] = useState("Account block");
    const [bodyMessage, setBodyMessage] = useState("Sorry your Star Points account has been blocked");
    const [actionText, setActionText] = useState("CALL 8111");
    const [icon, setIcon] = useState(BlockedIcon);
    const [actionLink, setActionLink] = useState(BlockedIcon);
    const [marginTop, setMarginTop] = useState(false);

    function logNegativeScenario(statusCode) {
        analytics.logEvent('star_p_negative_scenarios_view_screen', {statusCode : statusCode})
    }


    useEffect(() => {
        // LOYALTY_SERVICE_UNAVAILABLE
        if(props.starPoints.DATA && props.starPoints.DATA.statusCode === 1006){
            setHeaderContentMessage("Sorry")
            setBodyMessage("Something went wrong from our-end. Please try again later");
            setActionText("OK");
            setIcon(somethingWentWrong);
            setActionLink("exit")
            setMarginTop(true)
        }

        // No ACCOUNT STATUS
        if(props.starPoints.DATA && props.starPoints.DATA.statusCode === 1008){
            setHeaderContentMessage("No account found")
            setBodyMessage("Sorry you don’t have a Star Points account");
            setActionText("OK");
            setIcon(emptyBox);
            setActionLink("exit")
            setMarginTop(true)
        }

        // LOYALTY_SERVICE_UNAVAILABLE
        if(props.starPoints.DATA && props.starPoints.DATA.statusCode === 1010){
            setHeaderContentMessage("Account block")
            setBodyMessage("Sorry your Star Points account has been blocked");
            setActionText("CALL 141");
            setIcon(BlockedIcon);
            setActionLink("141")
            setMarginTop(false)
        }

        logNegativeScenario(props.starPoints.DATA && props.starPoints.DATA.statusCode ? props.starPoints.DATA.statusCode : 'N/A');
    }, [props.starPoints]);

    return (
        <Fragment>
            <MessageTemplate
                leftIcon={closeIcon}
                leftIconLink=""
                headerContentMessage={headerContentMessage}
                bodyMessage={bodyMessage}
                mainImage={icon}
                actionText={actionText}
                actionLink={actionLink}
                marginTop={marginTop}
            >

            </MessageTemplate>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const mapStateToProps = (response) => {
    return {
        starPoints: response.StarPointReducer.starPoints,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NegativeUserSenario);
