import React, { Fragment } from 'react';
import Header from '../UI/Header/Header';

const Layout = (props) => {

    return (
        <Fragment>
            {sessionStorage.getItem('isNavBarHidden') === 'true' && window.location.pathname === "/loyalty-finpal-app" ? null : <Header {...props} />}
            {props.children}
        </Fragment>

    )
}

export default Layout;
