/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from "react";
import Layout from "../../../../Layout/Layout";
import BodyContainer from "../../../../UI/BodyArea/Body";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import CircularLoading from "../../../../Common/CirculerLoading";
import Footer from '../../../../UI/FooterArea/Footer';
import Modal from "react-modal";
import errorIcon from "../../../../../Resources/offers/error_icon.svg";
import callIcon from "../../../../../Resources/offers/call_icon.svg";
import "../../../../../Common.scss";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import styled from 'styled-components'
import QrReader from 'react-qr-reader'
import ReactHtmlParser from 'react-html-parser';

import {
    redeemOfferAction,
    getCxOffersAction,
} from "../../../../../Actions/offersAction";


const QRCaption = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin: 40px;
    justify-content: center;
    align-items: center;
    `

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    ::-webkit-scrollbar {display:none;}
    `

const ThumbContainer = styled.div`
    position: relative;
    padding: 16px;
    background-color: #f4f4f8;
    `

const Thumb = styled.img`
    width: 100%;
    object-fit: cover;
    height: 229px;
    border-radius: 18px;
    `

const OfferCategoryContainer = styled.div`
    width: 160px;
    height: 35px;
    position: absolute;
    margin-top: -34px;
    background-color: white;
    border-radius: 0px 30px 0px 18px;
    `
const OfferCategoryInnerContainer = styled.div`
    display: flex;
    width: 154px;
    height: 29px;
    margin: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 29px 0px 17px;
    background-color: #00c2bc;
    `

const OfferCategory = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: white;
    `

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px;
    background-color: #ffffff;
    `

const MerchantName = styled.span`
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    `

const OfferTitle = styled.span`
    font-size: 20px;
    margin-top: 10px;
    font-weight: 400;
    color: #333333;
    `

const Validity = styled.span`
    width: 160px;
    height: 24px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    `

const Divider = styled.div`
    width: 100%;
    height: 1px;
    margin-top: 20px;
    background-color: #d8d8d870;
    `

const OfferDescription = styled.span`
    font-size: 14px;
    margin-top: 15px;
    font-weight: 400;
    color: #333333;
    `

const ContactIconContainer = styled.div`
    position: relative;
    margin-top: 20px;
    `
const CallIcon = styled.img`
    width: 40px;
    height: 40px;
    `

const WebIcon = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 23px;
    `

const RedeemButtonContainer = styled.div`
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    `

const RedeemButton = styled.span`
    width: 134px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #9846b6;
    font-size: 14px;
    border-radius: 17.5px;
    `

const ErrorIcon = styled.img`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    `

const ModalTitle = styled.span`
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    `

const ModalContent = styled.span`
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
    color: #333333;
    `

const ConfirmationButtonContainer = styled.div`
    width: 100%
    width: 40px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;
    `

const PositivieButton = styled.span`
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #ffffff;
    background-color: #9846b6;
    font-size: 14px;
    border-radius: 22px;
    `

const NegativeButton = styled.span`
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border: #333333;
    border-width: 1px; 
    border-style:outset;
    border-radius: 22px;
    `
const OfferDetail = (props) => {

    let history = useHistory();

    const loadingStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    };

    const customStyles = {
        overlay: {
            zIndex: '30000',
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        content: {
            width: '90%',
            top: 'auto',
            left: '5%',
            right: '5%',
            bottom: '30%',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '20px',
            color: '#000000)',
            padding: '10px',
            textAlign: 'center'
        }
    };

    const offer = props.location.state.offer;
    const offerType = offer.offer_type;
    const isMyLinxOffers = offer.offer_category === 'myLinuxOffers';
    const isOfferAvailable = offer.validity === 'Available';

    const [showModel, setShowModel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [offerDescription, setOfferDescription] = useState("");
    const [isConfirmation, setIsConfirmation] = useState(true);
    const [isOfferRedeemed, setIsOfferRedeemed] = useState(false);
    const [isRedeemOfferClicked, setIsRedeemOfferClicked] = useState(false);
    const [isQrCodeViewShowing, setIsQrCodeViewShowing] = useState(false);
    const [windowHeight, setWindowHeight] = useState(0);
    const [windowWidth, setWindowWidth] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const handleBackButton = () => {

        let isFrom = props.location.state.isFrom;

        switch (isFrom) {
            case 'HOME':
                return '/';
            case 'SEARCH':
                return '/offer-search';
            case 'LIST':
                return '/offers';
            case 'CATEGORY':
                return '/offer-categories';
            default:
                return '/';

        }
    }

    const handleNegativeButtonClick = () => {
        setShowModel(false);
    }

    const handleCloseModal = () => {
        setShowModel(false);
    }

    const afterOpenModal = () => {
    }

    const handleRedeemClick = () => {
        setIsConfirmation(true);
        setShowModel(true)
    }

    const handlePositiveButtonClick = () => {
        isConfirmation ? redeemOffer() : handleCloseModal()
    }

    const redeemOffer = () => {

        setShowModel(false);

        if (offer.offer_type === 'qr_code') {
            setIsQrCodeViewShowing(true);
            setWindowHeight(window.innerHeight);
            setWindowWidth(window.innerWidth);
        }

        if (offer.offer_type === 'promo_code') {
            setIsLoading(true);
            setIsRedeemOfferClicked(true);
            props.redeemOfferAction({
                reference: sessionStorage.getItem("offer_referrence"),
                campaign_id: offer.offer_id,
            });
        }


    }

    const decodeAndDisplayDescription = (description) => {
        let buff = new Buffer(description, 'base64');
        let encodedDescription = buff.toString('ascii');
        return ReactHtmlParser(encodedDescription);
    }

    useEffect(() => {

        if (isRedeemOfferClicked && props.redeemOffer.STATUS) {
            if (props.redeemOffer.STATUS === 'SUCCESS') {
                setOfferDescription(props.redeemOffer.DATA.response.message);
                setIsOfferRedeemed(true)
                props.getCxOffersAction();
            } else {
                setIsLoading(false);
                setErrorMessage("Something went wrong, please try again later.");
                setIsConfirmation(false);
                setShowModel(true);
            }
        }
    }, [props.redeemOffer]);

    useEffect(() => {

        if (props.cxOffers.STATUS) {
            setIsLoading(false);
            sessionStorage.setItem("offer_referrence", props.cxOffers.DATA.reference);
        }
    }, [props.cxOffers]);

    useEffect(() => {
        setIsOfferRedeemed(false);
        setOfferDescription(offer.offer_additional_info && offer.offer_additional_info !== '' ? decodeAndDisplayDescription(offer.offer_additional_info) : offer.offer_description);
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push(handleBackButton())
    }

    const handleScan = (data) => {
        console.log("data", data);
        if (data) {

            setIsQrCodeViewShowing(false);

            try {
                let qrData = JSON.parse(data);
                let mechatId = qrData["merchant_id"];
                let outletId = qrData["outlet_id"];

                if (mechatId && outletId) {
                    setIsLoading(true);
                    props.redeemOfferAction({
                        reference: sessionStorage.getItem("offer_referrence"),
                        merchant_id: mechatId,
                        outlet_id: outletId,
                        campaign_id: offer.offer_id
                    });
                } else {
                    setErrorMessage("Invalid QR code, please try again.");
                    setIsConfirmation(false);
                    setShowModel(true);
                }
            } catch (error) {
                setErrorMessage("Invalid QR code, please try again.");
                setIsConfirmation(false);
                setShowModel(true);
            }
        }

    }

    const handleError = (err) => {
        console.log(err);
        if (err) {
            setIsQrCodeViewShowing(false);
            setErrorMessage("Invalid QR code, please try again.");
            setIsConfirmation(false);
            setShowModel(true);
        }
    }


    return (
        isLoading ? (
            <div style={loadingStyles}>
                <CircularLoading />
            </div>
        ) :
            (
                <Fragment>
                    <Layout
                        leftIcon={ArrowLeftIcon}
                        headerTitle={isQrCodeViewShowing ? "Scan QR" : "Offers"}
                        leftIconLink={handleBackButton}
                    >
                        {isQrCodeViewShowing ?
                            <Container style={{ height: windowHeight, backgroundColor: 'black', borderRadius: 0 }}>
                                <div style={{ height: windowHeight, marginLeft: '40px', marginRight: '40px', marginTop: windowHeight / 2 - ((windowWidth - 80) / 2) }}>
                                    <QrReader
                                        delay={500}
                                        onError={handleError}
                                        onScan={handleScan}
                                        facingMode='environment'
                                    />

                                </div>
                                <QRCaption style={{ alignItems: 'center', justifyItems: 'center' }}>Place the code in the center of the square. It will be scanned automatically</QRCaption>


                            </Container> :
                            <BodyContainer>
                                <Container>
                                    <ThumbContainer>
                                        <Thumb style={{ height: (window.innerWidth - 32) / 1.5 }} src={isMyLinxOffers ? offer.offer_banner : offer.image_url} />
                                        {/* <OfferCategoryContainer>
                                            <OfferCategoryInnerContainer style={{ backgroundColor: isMyLinxOffers ?  '#00c2bc' : '#f4af6a' }}>
                                                <OfferCategory>{isMyLinxOffers ? 'Star Points' : 'Star Rewards'}</OfferCategory>
                                            </OfferCategoryInnerContainer>
                                        </OfferCategoryContainer> */}
                                    </ThumbContainer>
                                    <ContentContainer>
                                        <MerchantName>{offer.merchant_name}</MerchantName>
                                        <OfferTitle>{offer.offer_title}</OfferTitle>
                                        <Validity style={{ width: isMyLinxOffers ? '220px' : '160px', backgroundColor: isMyLinxOffers ? '#caf8fa' : isOfferAvailable ? '#6ed40040' : 'lightGrey', color: isMyLinxOffers ? '#2ab4de' : isOfferAvailable ? '#333333' : '#ffffff' }}>{isMyLinxOffers ? offer.validity : isOfferAvailable ? 'Available for redeem' : 'Offer redeemed'}</Validity>
                                        <Divider />
                                        <OfferDescription>{offerDescription}</OfferDescription>
                                        {offer.merchant_contact ? <ContactIconContainer>
                                            <a href={"tel:" + offer.merchant_contact}>
                                                <CallIcon src={callIcon} />
                                            </a>
                                        </ContactIconContainer>
                                            : null}
                                        {!isOfferRedeemed && offer.offer_type === 'promo_code' ? <RedeemButtonContainer>
                                            <a onClick={(e) => handleRedeemClick()}>
                                                <RedeemButton style={{ marginLeft: '0px' }}>{offerType === 'qr_code' ? 'SCAN QR' : 'GET OFFER'}</RedeemButton>
                                            </a>
                                        </RedeemButtonContainer> : null}
                                    </ContentContainer>
                                </Container>
                            </BodyContainer>}


                    </Layout>

                    <Footer>
                        <div>
                            <Modal
                                isOpen={showModel}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={handleCloseModal}
                                style={customStyles}
                                contentLabel="Error Modal"
                            >
                                <div className="container content">
                                    <div className="popUp">

                                        {isConfirmation ? <div className="row justify-content-center">
                                            <ModalTitle style={{ marginTop: '40px' }}> Are you sure </ModalTitle>
                                            <ModalTitle> you want to get this offer?</ModalTitle>
                                            <ConfirmationButtonContainer>
                                                <a onClick={handleNegativeButtonClick}>
                                                    <NegativeButton>NO</NegativeButton>
                                                </a>
                                                <a onClick={handlePositiveButtonClick}>
                                                    <PositivieButton style={{ marginLeft: '20px' }}>YES</PositivieButton>
                                                </a>
                                            </ConfirmationButtonContainer>
                                        </div> :
                                            <div className="row justify-content-center">
                                                <ErrorIcon src={errorIcon} />
                                                <ModalTitle style={{ marginTop: '40px' }}> Offer redemption failed </ModalTitle>
                                                <ModalContent> {errorMessage}</ModalContent>
                                                <ConfirmationButtonContainer>
                                                    <a onClick={handlePositiveButtonClick}>
                                                        <PositivieButton>OK</PositivieButton>
                                                    </a>
                                                </ConfirmationButtonContainer>
                                            </div>}

                                    </div>
                                </div>
                            </Modal>
                        </div>

                    </Footer>
                </Fragment>)
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        redeemOfferAction: (request) => dispatch(redeemOfferAction(request)),
        getCxOffersAction: () => dispatch(getCxOffersAction())

    };
};

const mapStateToProps = (response) => {
    return {
        redeemOffer: response.OffersReducer.redeemOffer,
        cxOffers: response.OffersReducer.cxOffers,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetail);

