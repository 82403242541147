import React, { useState, useEffect, Fragment } from "react";
import styles from "./StarPointCard.module.scss";
import firebase from 'firebase/app';
import starPointsLogo from "../../../../Resources/shared/starpointsLogo.png";
import dialogLogo from "../../../../Resources/shared/dialog_logo.png";
import DropdownIcon from "../../../../Resources/shared/chevron_down.svg";
import TransactionDetails from "./StarPointsDetailsOverlay";

const StarPointCard = ({
  transactionType,
  merchant,
  amount,
  transactionsDetails,
}) => {

  const analytics = firebase.analytics();

  const [amountInt, setAmountInt] = useState("0");
  const [amountDecimal, setAmountDecimal] = useState("0");
  const [showDetails, setShowDetails] = useState(false);

  const splitDecimal = (value) => {
    let numArray = value.toString().split(".");
    return numArray;
  };

  function logViewDetailsOfSingleTransaction() {
    //console.log("logging analytics : star_p_view_details_single_transaction");
    analytics.logEvent('star_p_view_trans_page_view_single_tran', {transactionType : transactionType})
  }

  useEffect(() => {
    let numArray = splitDecimal(amount);
    setAmountInt((numArray[0]));
    setAmountDecimal(numArray[1] ? (numArray[1]) : 0);
  }, [amount]);

  const handleCLose = () => {
    setShowDetails(false);
  };
  //   const showOverlay = useEffect(() => {
  //     if (setShowDetails) console.log("asdfasdfasdfasf");
  //   });

  return (
    <Fragment>
      {showDetails === true && (
        <TransactionDetails
          handleCLose={handleCLose}
          transactionsDetails={transactionsDetails}
        />
      )}
      <div className={styles.card}>
        <div className={styles.card_image}>
          <img
            src={transactionsDetails && transactionsDetails.merchant && transactionsDetails.merchant.toLowerCase().includes("dialog") ? dialogLogo : starPointsLogo}
            alt="Logo"
            className={styles.card_image__logo}
          />
        </div>
        <div className={styles.card_content}>
          <div
            className={styles.card_content__upper}
          >{transactionsDetails.transactionTypeText}</div>
          <div className={styles.card_content__bottom}>
            <div>{merchant ? merchant : transactionsDetails.transactionTypeText}</div>
            <div className={styles.card_content__cost}>
              <span
                className={
                  transactionType === "EARN" ? styles.value_earn : undefined
                }
              >
                {transactionType === "EARN" ? "+" : null }
                {transactionType === "EARN_REVERSAL" ? "-" : null }
                {transactionType === "EARN_ADJUSTED" ? "+" : null }
                {transactionType === "EARN_ADJUSTMENT" ? "-" : null }
                {transactionType === "BURN" ? "-" : null }
                {transactionType === "BURN_REVERSAL" ? "+" : null }
                {transactionType === "BURN_ADJUSTED" ? "-" : null }
                {transactionType === "BURN_ADJUSTMENT" ? "+" : null }
                {transactionType === "EXPIRY" ? "-" : null }
              </span>
              <span
                className={
                  transactionType === "EARN" ? styles.value_earn : undefined
                }
              >
                {amountInt}
              </span>
              <span
                className={
                  transactionType === "EARN" ? styles.value_earn : undefined
                }
              >{`.${amountDecimal}${amountDecimal === 0 ? 0 : ""}`}</span>
              <span>
                <img
                  src={DropdownIcon}
                  alt="Logo"
                  onClick={() => {
                    logViewDetailsOfSingleTransaction();
                    setShowDetails(true);
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StarPointCard;
