import React, { Fragment } from 'react';

//import PullToRefresh from 'rmc-pull-to-refresh';

// import PullToRefresh from 'react-pull-to-refresh';

// import {PullToRefresh} from 'react-js-pull-to-refresh';

// import { ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

function Body(props){
    const appStyle={
        width:'100%',
        position:'relative',
        top:'3.5rem',
        marginBottom:'0%',
        overFlow: 'auto',
    }

    return(
        <Fragment>
                <div style={appStyle}>
                    {props.children}
                </div>
        </Fragment>
    );
}
export default Body;
