/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import axios from 'axios';
import dotenv from "dotenv";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from "react-modal";

dotenv.config();

class ApiClient {

  constructor() {

    let BASE_URL = process.env.REACT_APP_API_URL;

    const token = sessionStorage.getItem("token");
    console.log("token", token);
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.BASE_URL = BASE_URL;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    switch (error.response.status) {
      case 401: case 403: case 400:
        this.showErrorMessage();
        break;
      case 404:
        this.redirectTo(document, '/404')
        break;
      default:
        //this.redirectTo(document, '/500')
        break;
    }
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    console.log("pathname", window.location.pathname);
    document.location = path
  }

  get(path, params, callback) {
    const token = sessionStorage.getItem("token");
    return this.service.request({
      method: 'GET',
      url: this.BASE_URL + path,
      headers: { Authorization: "Bearer " + token },
      params: params
    }).then(
      (response) => callback(response.data)
    );
  }

  patch(path, params, payload, callback) {
    const token = sessionStorage.getItem("token");
    return this.service.request({
      method: 'PATCH',
      url: this.BASE_URL + path,
      headers: { Authorization: "Bearer " + token },
      responseType: 'json',
      params: params,
      data: payload
    }).then((response) => callback(response.data));
  }

  post(path, params, payload, callback) {
    const token = sessionStorage.getItem("token");
    return this.service.request({
      method: 'POST',
      url: this.BASE_URL + path,
      headers: { Authorization: "Bearer " + token },
      responseType: 'json',
      data: payload,
      params: params
    }).then((response) => callback(response.data));
  }

  showErrorMessage = () => {

    const customStyles = {
      overlay: {
        zIndex: '30000',
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
      },
      content: {
        width: '90%',
        top: 'auto',
        left: '5%',
        right: '5%',
        bottom: '30%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '20px',
        color: '#000000)',
        padding: '10px',
        textAlign: 'center'
      }
    };

    confirmAlert({
      customUI: () => {
        return (
          <div>
            <Modal
              isOpen={true}
              style={customStyles}
              contentLabel="Error Modal"
            >
              <div className="container content">
                <div className="pop_up">
                  <div className="row justify-content-center">
                    <div className="justify-content-center" style={{ width: '65%' }}>
                      <h6 className="errorModalMainText">
                        Session Expired
                      </h6>
                    </div>

                  </div>
                  <div className="row justify-content-center" style={{ marginTop: '-2.3em' }}>
                    <div className="justify-content-center" style={{ width: '100%' }}>
                      <h6 className="errorModalSubText">
                        Your Star Points session has been expired.
                      </h6>
                    </div>

                  </div>
                  <div className="justify-content-center" style={{ width: '100%' }}>
                  <a onClick={this.goToHomePage}>
                  <button className="btn-transaction remove-outline" >
                    OK
                  </button>
                  </a>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        );
      }
    });
  };

  goToHomePage = () => {
    window.ReactNativeWebView.postMessage('Exit');
  }
}

export default new ApiClient();

