import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import SelfRedemptionSuccess from './Pages/RedemptionSucess.js';

const SelfRedemptionSuccessRoute = () => {
    return (
        <Fragment>
            <Route path="/self-redemption-success" component={SelfRedemptionSuccess} />
        </Fragment>
    )
}

export default withRouter(SelfRedemptionSuccessRoute)
