import * as types from '../Actions';

const initailState = {
    tierPoints: {}
};

export default function (state = initailState, action) {
    let response = action.response;
    
    switch (action.type) {
        // Fetch tierPoints
        case types.FETCH_TIER_POINTS_SUCCESS:
            return { ...state, tierPoints: response };
        case types.FETCH_TIER_POINTS_FAIL:
            return { ...state, tierPoints: response };

        default:
            return state;
    }
}