import React, { useState, useEffect, Fragment } from "react";
import styles from "./StarPoints.module.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TransactionsDate from "./TransactionsDate";
import StarPointCard from "./StarPointCard";
import CircularLoading from "../../../Common/CirculerLoading";
import TransationEmptyIcon from "../../../../Resources/transfer/transaction_empty.svg";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import { fetchTransactionsAction } from "../../../../Actions/transactionsAction";
import moment from "moment";
import Modal from 'react-modal';
import PullToRefresh from "react-simple-pull-to-refresh";
import BodyContainer from "../../../UI/BodyArea/Body";
import Layout from "../../../Layout/Layout";

const loadingStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const StarPoints = (props) => {

  const [transactionHistoryByDate, setTransactionHistoryByDate] = useState([]);
  const [transactionDataLoaded, setTransactionDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noTransactions, setNoTransactions] = useState(false);
  const [showModel, setShowModel] = useState(true);
  const [messageBoxText] = useState("Latest transactions may not reflect here. It will be updated shortly");
  let history = useHistory();

  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const handleOpenModal = () => {
    setShowModel(false);
  }

  const splitDecmial = (value) => {
    let numArray = value.toString().split(".");
    return numArray;
  };

  function getFormatedValue(val) {
    let arrayNum = splitDecmial(val);
    if (arrayNum.length === 0) {
      return "00.00";
    } else if (arrayNum.length === 1) {
      return arrayNum[0] + ".00";
    } else if (arrayNum[1].length === 1) {
      return arrayNum[0] + "." + arrayNum[1] + "0";
    } else if (arrayNum[1].length === 2) {
      return arrayNum[0] + "." + arrayNum[1];
    } else {
      return val;
    }
  }

  const handleCloseModal = () => {
    setShowModel(false);
  }

  const afterOpenModal = () => {
  }

  const customStyles = {
    content: {
      width: '98%',
      top: 'auto',
      left: '1%',
      right: '1%',
      bottom: '1%',
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: 'rgba(255, 255, 255, 0.87)',
      padding: '10px'
    }
  };

  useEffect(() => {
    props.fetchTransactionsAction();
  }, [props.fetchTransactionsAction]);

  useEffect(() => {

    // Safely initiate the modal
    Modal.setAppElement('body');

    if (props.transactions.STATUS === "SUCCESS") {

      setIsLoading(false);

      if (Array.isArray(props.transactions.DATA) && props.transactions.DATA.length > 0) {
        console.log('parsing values.....');
        const initArr = props.transactions.DATA.sort((a, b) => (moment(b.txDate, "DD-MM-YYYY") - moment(a.txDate, "DD-MM-YYYY")))
        let sortedArray = [];
        initArr.forEach(element => {

          let date = moment(element.txDate, "DD-MM-YYYY");
          element.sortDate = date.format('LL');

          let inserted = false;

          for (let i = 0; i < sortedArray.length; i++) {
            if (sortedArray[i] && sortedArray[i].length > 0) {
              if (sortedArray[i][0]) {
                if (sortedArray[i][0].sortDate === element.sortDate) {
                  sortedArray[i][sortedArray[i].length] = element;
                  inserted = true;
                }

              }
            }
          }

          if (!inserted) {
            sortedArray.push([element]);
          }
        });

        setTransactionHistoryByDate(sortedArray);
        setNoTransactions(false);
        setTransactionDataLoaded(true);

      } else {
        setNoTransactions(true);
      }

    }

  }, [props.transactions]);

  useEffect(() => {

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    history.push("/")
  }
  return (

    <Fragment>
      {isLoading ? (
        <div style={loadingStyles}>
          <CircularLoading />
        </div>
      ) : (
        <Layout
          leftIcon={ArrowLeftIcon}
          headerTitle="Transactions history"
          leftIconLink="/"
        >

          <BodyContainer>

            <PullToRefresh onRefresh={() => history.push('/transaction-history')}
              refreshingContent=" "
              pullingContent=" "
              pullDownThreshold={100}
              maxPullDownDistance={50}
            >
              <header className="App-container">
                <div style={{ width: "100%" }}>
                  {transactionDataLoaded && transactionHistoryByDate &&
                    transactionHistoryByDate.length === 0 ? (
                    <div className={styles.transactionEmptyIcon_container}>
                      <div className={styles.empty_heading}>
                        Loading..
                      </div>
                    </div>
                  ) : (
                    <div className={styles.container}>
                      <div style={{ height: "10px" }}></div>
                      {
                        transactionHistoryByDate.map(entry => {
                          return (
                            <div
                              className={styles.container_starpoint_container}
                              key={entry[0] ? entry[0].sortDate : Math.random().toString(36).substring(7)}
                            >
                              {entry.length > 0 && (
                                <TransactionsDate date={
                                  entry[0].sortDate === moment().format("LL") ? "Today" :
                                    entry[0].sortDate === moment().subtract(1, "Day").format("LL") ?
                                      "Yesterday" : entry[0].sortDate
                                } />
                              )}
                              {entry.map((transaction) => {
                                return (
                                  <StarPointCard
                                    key={transaction.transactionSerial}
                                    transactionType={transaction.transactionType}
                                    merchant={transaction.merchant ? transaction.merchant : ""}
                                    amount={getFormatedValue(transaction.amount)}
                                    transactionsDetails={transaction}
                                  />
                                );
                              })}
                            </div>
                          )
                        })
                      }
                    </div>
                  )}
                  {noTransactions ?
                    <div className={styles.transactionEmptyIcon_container}>
                      <img src={TransationEmptyIcon} alt="Logo" />
                      <div className={styles.empty_heading}>
                        No transaction history found
                      </div>

                      <div>
                        <Modal
                          isOpen={showModel}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={handleCloseModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <div className="row popUp">
                            <div className="col-9">
                              <p className="popUpTextSmall">
                                {messageBoxText}
                              </p>
                            </div>
                            <div className="col-3" >
                              <p className="popUpTextMedium" onClick={handleOpenModal}>
                                OK
                              </p>
                            </div>
                          </div>

                        </Modal>
                      </div>
                    </div> : ""}
                </div>
              </header>
            </PullToRefresh>
          </BodyContainer>
        </Layout>
      )}
    </Fragment>

  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactionsAction: () => dispatch(fetchTransactionsAction()),
  };
};

const mapStateToProps = (response) => {
  return {
    transactions: response.TransactionReducer.transactions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarPoints);
