import ApiClient from "./ApiClient";

/**
 * fetch starpoints
 * @param {object} request
 */
export const fetchStarpointsService = async () => {
 
  const ENDPOINT = `core/subscriber`;
  
  let params = {
    lob: sessionStorage.getItem('subscriberType')
  }

  let api = await ApiClient.get(
    ENDPOINT,
    params,
    (response) => {
      console.log("****** Get star points detais response *******", response);
      return response
    }
  );

  return api;

};

