import { put, call } from "redux-saga/effects";

import * as types from "../Actions";
import {
    getMobileNumberService,
    getConnectionUnderNicSevice,
    getLobForConnectionService
} from "../Services/mobileNumberService";

export function* getMobileNumberSaga() {
    try {
        let response = yield call(getMobileNumberService)

        if (response && response.success) {
            yield put({ type: types.GET_MOBILE_NUMBER_SUCCESS, response })
        } else {
            yield put({ type: types.GET_MOBILE_NUMBER_FAIL, response })
        }
    } catch (error) {
        yield put({ type: types.GET_MOBILE_NUMBER_FAIL, response: error })
    }
}

export function* getConnectionUnderNicSaga() {
    try {
        let response = yield call(getConnectionUnderNicSevice)

        if (response && response.success) {
            yield put({ type: types.GET_CONNECTIONS_UNDER_NIC_SUCCESS, response })
        } else {
            yield put({ type: types.GET_CONNECTIONS_UNDER_NIC_FAIL, response })
        }
    } catch (error) {
        yield put({ type: types.GET_CONNECTIONS_UNDER_NIC_FAIL, response: error })
    }
}

export function* getLobForConnectionSaga() {
    try {
        let response = yield call(getLobForConnectionService)

        if (response && response.success) {
            yield put({ type: types.GET_LOB_SUCCESS, response })
        } else {
            yield put({ type: types.GET_LOB_FAIL, response })
        }
    } catch (error) {
        yield put({ type: types.GET_LOB_FAIL, response: error })
    }
}