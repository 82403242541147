import { combineReducers } from 'redux'

import StarPointReducer from './starPointReducer'
import TransactionReducer from './transactionsReducer'
import DonateOrganizationReducer from './donateOrganizationReducer'
import AuthTransactionReducer from './authTransactionReducer'
import TierPointsReducer from './tierPointsReducer'
import MobileNumberReducer from './mobileNumberReducer'
import OffersReducer from './offersReducer'

const rootReducer = combineReducers({
    StarPointReducer,
    TransactionReducer,
    DonateOrganizationReducer,
    AuthTransactionReducer,
    TierPointsReducer,
    MobileNumberReducer,
    OffersReducer
});
export default rootReducer;