// fetch starpoints
export const FETCH_STARPOINTS = "FETCH_STARPOINTS";
export const FETCH_STARPOINTS_SUCCESS = "FETCH_STARPOINTS_SUCCESS";
export const FETCH_STARPOINTS_FAIL = "FETCH_STARPOINTS_FAIL";

export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS"
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS"
export const FETCH_TRANSACTIONS_FAIL = "FETCH_TRANSACTIONS_FAIL"

export const FETCH_DONATION_ORGANIZATIONS = "FETCH_DONATION_ORGANIZATIONS"
export const FETCH_DONATION_ORGANIZATIONS_SUCCESS = "FETCH_DONATION_ORGANIZATIONS_SUCCESS"
export const FETCH_DONATION_ORGANIZATIONS_FAIL = "FETCH_DONATION_ORGANIZATIONS_FAIL"

export const FETCH_TIER_POINTS = "FETCH_TIER_POINTS"
export const FETCH_TIER_POINTS_SUCCESS = "FETCH_TIER_POINTS_SUCCESS"
export const FETCH_TIER_POINTS_FAIL = "FETCH_TIER_POINTS_FAIL"

export const GET_MOBILE_NUMBER = "GET_MOBILE_NUMBER"
export const GET_MOBILE_NUMBER_SUCCESS = "GET_MOBILE_NUMBER_SUCCESS"
export const GET_MOBILE_NUMBER_FAIL = "GET_MOBILE_NUMBER_FAIL"

export const GET_DIALOG_OFFERS = "GET_DIALOG_OFFERS"
export const GET_DIALOG_OFFERS_SUCCESS = "GET_DIALOG_OFFERS_SUCCESS"
export const GET_DIALOG_OFFERS_FAIL = "GET_DIALOG_OFFERS_FAIL"

export const GET_MERCHANT_OFFERS = "GET_MERCHANT_OFFERS_OFFERS"
export const GET_MERCHANT_OFFERS_SUCCESS = "GET_MERCHANT_OFFERS_SUCCESS"
export const GET_MERCHANT_OFFERS_FAIL = "GET_MERCHANT_OFFERS_FAIL"

export const REQUEST_OTP = "REQUEST_OTP"
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS"
export const REQUEST_OTP_FAIL = "REQUEST_OTP_FAIL"

export const FETCH_ORGANIZATIONS_DONATE = "FETCH_ORGANIZATIONS_DONATE"
export const FETCH_ORGANIZATIONS_DONATE_SUCCESS = "FETCH_ORGANIZATIONS_DONATE_SUCCESS"
export const FETCH_ORGANIZATIONS_DONATE_FAIL = "FETCH_ORGANIZATIONS_DONATE_FAIL"

export const MAKE_SELF_REDEMPTION = "MAKE_SELF_REDEMPTION"
export const MAKE_SELF_REDEMPTION_SUCCESS = "MAKE_SELF_REDEMPTION_SUCCESS"
export const MAKE_SELF_REDEMPTION__FAIL = "MAKE_SELF_REDEMPTION__FAIL"

export const GET_CONNECTIONS_UNDER_NIC = "GET_CONNECTIONS_UNDER_NIC"
export const GET_CONNECTIONS_UNDER_NIC_SUCCESS = "GET_CONNECTIONS_UNDER_NIC_SUCCESS"
export const GET_CONNECTIONS_UNDER_NIC_FAIL = "GET_CONNECTIONS_UNDER_NIC__FAIL"

export const MAKE_TRASFER_POINTS = "MAKE_TRASFER_POINTS"
export const MAKE_TRASFER_POINTS_SUCCESS = "MAKE_TRASFER_POINTS_SUCCESS"
export const MAKE_TRASFER_POINTS_FAIL = "MAKE_TRASFER_POINTS_FAIL"

export const GET_CX_OFFERS = "GET_CX_OFFERS"
export const GET_CX_OFFERS_SUCCESS = "GET_CX_OFFERS_SUCCESS"
export const GET_CX_OFFERS_FAIL = "GET_CX_OFFERS_FAIL"

export const REDEEM_OFFER = "REDEEM_OFFER"
export const REDEEM_OFFER_SUCCESS = "REDEEM_OFFER_SUCCESS"
export const REDEEM_OFFER_FAIL = "REDEEM_OFFER_FAIL"

export const GET_LOB = "GET_LOB"
export const GET_LOB_SUCCESS = "GET_LOB_SUCCESS"
export const GET_LOB_FAIL = "GET_LOB_FAIL"