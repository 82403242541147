import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import TransferPoints from './Pages/TransferConfirmation';

const TransferConfirmationRoute = () => {
    return (
        <Fragment>
            <Route path="/transfer-points-confirmation" component={TransferPoints} />
        </Fragment>
    )
}

export default withRouter(TransferConfirmationRoute)