import { takeLatest } from 'redux-saga/effects';

import * as types from '../Actions';

import {
    fetchStarpointsSaga
} from './starpointsSage'

import {
    fetchTransactionsSaga
} from './transactionSaga'

import {
    donateOrganizationSaga
} from './donateOrganizatioSaga'

import {
    requestOTPSaga,
    selfRedemptionSaga,
    makeDonateSaga,
    makeTransferPointsSaga
} from './authTransactionSaga'

import {
    fetchTierPointsSaga
} from './tierPointsSaga'

import {
    getMobileNumberSaga,
    getConnectionUnderNicSaga,
    getLobForConnectionSaga
} from './mobileNumberSaga'

import {
    getDialogOffersSaga,
    getMerchantOffersSaga,
    getCxOffersSaga,
    redeemOffersSaga
} from './offersSaga'

export default function* watchUserAction() {
    // starpoints
    yield takeLatest(types.FETCH_STARPOINTS, fetchStarpointsSaga)
   
    //transactions
    yield takeLatest(types.FETCH_TRANSACTIONS, fetchTransactionsSaga)

    //donate organization
    yield takeLatest(types.FETCH_DONATION_ORGANIZATIONS, donateOrganizationSaga)

    //fetch tier points
    yield takeLatest(types.FETCH_TIER_POINTS, fetchTierPointsSaga)

    //get mobile number
    yield takeLatest(types.GET_MOBILE_NUMBER, getMobileNumberSaga)

    //get dialog offers
    yield takeLatest(types.GET_DIALOG_OFFERS, getDialogOffersSaga)

    //get merchant offers
    yield takeLatest(types.GET_MERCHANT_OFFERS, getMerchantOffersSaga)

    //get cx offers
    yield takeLatest(types.GET_CX_OFFERS, getCxOffersSaga)

     //Redeem offers
     yield takeLatest(types.REDEEM_OFFER, redeemOffersSaga)

    //Auth tranctions
    yield takeLatest(types.REQUEST_OTP, requestOTPSaga)
    yield takeLatest(types.MAKE_SELF_REDEMPTION, selfRedemptionSaga)
    yield takeLatest(types.FETCH_ORGANIZATIONS_DONATE, makeDonateSaga)
    yield takeLatest(types.MAKE_TRASFER_POINTS, makeTransferPointsSaga)

    //get connection uder nic
    yield takeLatest(types.GET_CONNECTIONS_UNDER_NIC, getConnectionUnderNicSaga)

     //get mobile number
     yield takeLatest(types.GET_LOB, getLobForConnectionSaga)

}