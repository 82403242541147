import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import DonateSubmit from './Pages/DonateSubmit';

const DonateSubmitRoute = () => {
    return (
        <Fragment>
            <Route path="/donate-submit" component={DonateSubmit} />
        </Fragment>
    )
}

export default withRouter(DonateSubmitRoute)
