/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, Fragment } from 'react';
import Layout from '../../../../Layout/Layout';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import starPoints from "../../../../../Resources/shared/starpointsLogo.png";
import dialog from "../../../../../Resources/transfer/dialog_mobile.svg";
import dropDownIcon from "../../../../../Resources/shared/dropDown.svg";
import Footer from '../../../../UI/FooterArea/Footer';
import BodyContainer from '../../../../UI/BodyArea/Body';
import "./../../../../../Common.scss";
import NumberFormat from 'react-number-format';
import ConnectionsUnderNic from "./ConnectionsUnderNicOverlay";

const TransferPoints = (props) => {
    const [transferrablePoints, setTransferrablePoints] = useState("0");
    const [transferrablePointsDecimal, setTransferrablePointsDecimal] = useState("00");
    const [pointAmount, setPointAmount] = useState(0.0);
    const [redeemDisabled, setRedeemDisabled] = useState(true);
    const [transferrableAmountFloat, setTransferrableAmountFloat] = useState(0);
    const [showDetails, setShowDetails] = useState(false);
    const [isBarCodeNumber, setIsBarCodeNumber] = useState(false);
  
    let history = useHistory();

    const handleNextClick = () => {
         sessionStorage.setItem("pointsAmount", pointAmount);
         history.push('/transfer-points-confirmation')
    }

    const handleDropDownClick = () => {
        setShowDetails(true);
    }

    const splitDecmial = (value) => {
        let numArray = value.toString().split(".");
        return numArray;
    };

    const numberWithCommas = (x) => {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }

    const onPointValueChange = (event) => {
        let value = parseFloat(event.target.value.replaceAll(",", ""));

        if (value) {
            setPointAmount(value);
            if (value <= transferrableAmountFloat) {
                setRedeemDisabled(false);
            } else {
                setRedeemDisabled(true);
            }
        } else {
            setRedeemDisabled(true);
        }

    }

    const handleCLose = () => {
        setShowDetails(false);
    };

    useEffect(() => {

        if (props.starPoints) {
            if (props.starPoints.DATA && props.starPoints.DATA.balance) {
                let numArray = splitDecmial(props.starPoints.DATA.balance.redeemableBalance);
                setTransferrablePoints((numArray[0]));

                setTransferrablePointsDecimal((numArray[1]) ? (numArray[1]).length === 1 ? (numArray[1]) + "0" : (numArray[1]) : "00");

                //setting for common usage here
                let redeemablePointsFloat = parseFloat(props.starPoints.DATA.balance.redeemableBalance);
                setTransferrableAmountFloat(redeemablePointsFloat);

                let connectionNumber = sessionStorage.getItem("connectionReference");

                if  (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
                    setIsBarCodeNumber(true);
                } else {
                    setIsBarCodeNumber(false);
                }
            }
        }

    }, [props.starPoints, showDetails]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/star-points-transfer")
    }

    const getTextFromConnectionNumber = (connectionNumber) => {

        if  (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
            return 'Cargills'.concat(" ", connectionNumber.replace(/(\d{0})\d{4}/, "$1****"));
        } else {
            return connectionNumber;
        }
    }

    return (
            <Fragment>
                <div>
                    <Layout
                        leftIcon={ArrowLeftIcon}
                        rightIcon={dialog}
                        headerTitle="Star Points Transfer"
                        leftIconLink="/star-points-transfer"
                    >
                        <BodyContainer>

                            <header className="App-container">
                                <div style={{ overflow: "hidden", width: "100%", height: "100%", backgroundColor: "#ffffff" }}>
                                    <div style={{ paddingTop: '7%' }}>

                                        <a onClick={handleDropDownClick}>
                                            <div className="col-12" style={{ marginLeft: isBarCodeNumber ? '23%' : '30%', borderRadius: '10px', backgroundColor: '#f6f6f9', display: 'flex', width: isBarCodeNumber ? '52%' : '40%', height: '36px', alignItems: 'center', justifyContent: 'center' }}>
                                                <div className="col" style={{ fontSize: '14px', fontWeight: 'medium', whiteSpace: 'nowrap'}}>{getTextFromConnectionNumber(sessionStorage.getItem("connectionReference"))}</div>
                                                <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={dropDownIcon} className="drop-down" alt="logo" />
                                                </div>
                                            </div>
                                        </a>
                                        <div style={{ fontSize: '14px', fontWeight: 'medium', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp', marginTop: '50px' }} >
                                            Transfer
                                        </div>
                                        <div className="row" style={{ justifyContent: 'center', marginTop: '-1.5em' }}>
                                            <NumberFormat
                                                className="points-input"
                                                thousandSeparator={true}
                                                inputMode="decimal"
                                                name="points-input"
                                                onChange={(e) => onPointValueChange(e)}
                                                placeholder='0'
                                            />
                                        </div>

                                        <div className="row" style={{ fontSize: '15px', marginTop: "-70px", fontWeight: 'bold', display: 'flex', justifyContent: 'center', lineHeight: '16sp' }}>
                                            {isNaN(pointAmount) ?
                                                <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Please enter value</div> : ""}
                                            {pointAmount > transferrableAmountFloat ?
                                                <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Insufficient Points balance</div> : ""}
                                        </div>
                                    </div>

                                </div>

                            </header>
                        </BodyContainer>
                    </Layout>
                    <Footer>
                        <div className="row" style={{ position: 'sticky', bottom: 0, left: 0, right: 0 }}>
                            <div className="col-12">
                                <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 'medium' }}>
                                    <img src={starPoints}
                                        style={{
                                            backgroundColor: 'white',
                                            width: '24px',
                                            height: '24px',
                                            marginRight: '10px',
                                            fontFamily: 'OpenSans'
                                        }} />
                                    Redeemable Points : {numberWithCommas(transferrablePoints)}.
                                    <span style={{ fontSize: '14px' }}>{transferrablePointsDecimal}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <a onClick={handleNextClick} style={{ color: 'white' }}>
                                    <button className="btn-transaction remove-outline" disabled={redeemDisabled}>NEXT</button>
                                </a>
                            </div>
                        </div>

                        {showDetails === true && (
                            <ConnectionsUnderNic
                                handleCLose={handleCLose}
                            />
                        )}
                    </Footer>


                </div>
                
            </Fragment>
    );
}

const mapStateToProps = (response) => {
    return {
        starPoints: response.StarPointReducer.starPoints,
    };
};

export default connect(mapStateToProps)(TransferPoints);
