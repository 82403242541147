import React from 'react';
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import HELPAGE_COUNTER from "../../../../Resources/donate/HELPAGE_COUNTER.png";
import UNICEF_COUNTER from "../../../../Resources/donate/UNICEF_COUNTER.png";
import LITTLEHEARTS from "../../../../Resources/donate/LITTLEHEARTS.png";
import TRAIL_SPAPP from "../../../../Resources/donate/TRAIL_SPAPP.png";
import MY_FRIEND from "../../../../Resources/donate/MY_FRIEND.png";
import SOS_CV_SL from "../../../../Resources/donate/SOS_CV_SL.png";
import ATLAS_SIP_SAVI from "../../../../Resources/donate/ATLAS_COUNTER.png";
import AYATI from "../../../../Resources/donate/AYATI.png";
import CCC_FOUNDATION from "../../../../Resources/donate/CCC_FOUNDATION.png";
import CA_LANKA from "../../../../Resources/donate/CA_LANKA.png";
import dialog_logo from "../../../../Resources/shared/dialog_logo.png";

import Layout from "../../../Layout/Layout";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BodyContainer from '../../../UI/BodyArea/Body';
import CircularLoading from "../../../Common/CirculerLoading";
import Footer from '../../../UI/FooterArea/Footer';
import {
  fetchDonationOrganizationsAction
} from "../../../../Actions/donationOrganizationsAction";
import "./../../../../Common.scss";
import firebase from 'firebase/app';

const StarPointDonates = (props) => {

  const analytics = firebase.analytics();
  const [isLoading, setIsLoading] = useState(true);
  const [array, setArray] = useState([]);
  const [searchArray, setSearchArray] = useState(array);
  let history = useHistory();

  const loadingStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  let handleMerchantClick = (event, item) => {
    sessionStorage.setItem("donateMerchantAlias", item.donationCounterAlias)
    logDonationPartnerSelection(item.donationCounterAlias);
    sessionStorage.setItem("donateMerchantDisplayName", item.donationDisplayName)
    history.push('/points-amount')
  }

  const logDonationPartnerSelection = (partnerName) => {
    console.log("logging analytics : star_p_partner_selection_screen_action", partnerName);
    analytics.logEvent('star_p_partner_selection_screen_action', { partnerName: partnerName })
  }

  useEffect(() => {
    props.fetchDonationOrganizationsAction();
  }, [props.fetchDonationOrganizationsAction]);

  function getImage(alias) {
    switch (alias) {
      case "HELPAGE_COUNTER": return HELPAGE_COUNTER;
      case "UNICEF_COUNTER": return UNICEF_COUNTER;
      case "LITTLEHEARTS": return LITTLEHEARTS;
      case "TRAIL_SPAPP": return TRAIL_SPAPP;
      case "MY FRIEND": return MY_FRIEND;
      case "SOS_CV_SL": return SOS_CV_SL;
      case "ATLAS_1": return ATLAS_SIP_SAVI;
      case "0075": return AYATI;
      case "171": return CCC_FOUNDATION;
      case "CA_LANKA": return CA_LANKA;
      default: return dialog_logo;
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    console.log(array);
    const { name, value } = event.target;
    if (name === "searchInputField") {
      if (value === "" || value === null) {
        setSearchArray(array);
      } else {
        setSearchArray(array.filter(org => org.donationDisplayName.toUpperCase().includes(value.toUpperCase())));
      }
    }
  }

  useEffect(() => {
    if (props.donateOrganaizations) {
      setIsLoading(false);
      setArray(props.donateOrganaizations.DATA);
      setSearchArray(props.donateOrganaizations.DATA);
    }
  }, [props.donateOrganaizations]);

  useEffect(() => {

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
    }

}, []);

const onBackButtonEvent = (e) => {
    e.preventDefault();
    history.push("/")
}
  return (
    <Fragment>
      {isLoading ? (
        <div style={loadingStyles}>
          <CircularLoading />
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <Layout
            leftIcon={ArrowLeftIcon}
            headerTitle="Star Points"
            leftIconLink="/"
          >
            <BodyContainer>
              <header className="App-container">
                <div style={{ width: "100%", backgroundColor: "#ffffff" }}>
                  <div className="inputIcons" style={{ paddingTop: '20px' }}>
                    <i className="fa fa-search icon" style={{ paddingTop: '10px', paddingBottom: '10px' }}></i>
                    <input className="inputField"
                      name="searchInputField"
                      onChangeCapture={handleChange}
                      type="text"
                      style={{ paddingTop: '10px', paddingBottom: '10px' }}
                      placeholder="Search merchants" />
                  </div>
                  <div className="row" style={{ marginLeft: '0.2em' }}>
                    <p style={{ color: '#767676', fontWeight: 'bold' }}>Donations</p>
                  </div>
                  {/* donation list */}
                  <div
                    className="row"
                    style={{
                      backgroundColor: "#f9f9f9",
                      padding: "5px",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      marginTop: '-1em'
                    }}
                  >
                    <div className="flex-container" style={{ margin: 0, padding: 0 }}>
                      {
                        /** set image list */
                        searchArray.map((item, index) => (
                          // <div className="container"  style={{ margin: 0, padding: 0 }}>
                          <div
                            key={index}
                            className="row"
                            style={{ backgroundColor: "#f9f9f9", padding: "5px" }}
                          >
                            <div className="col-3">
                              <img src={getImage(item.donationCounterAlias)} key={item.donationID} alt="logo" className="avatar" />
                            </div>
                            <div className="col-8"
                              style={{
                                textAlign: "left",
                                justifyContent: "center",
                                alignItems: "center",
                                borderBottom: "1px solid #edeff5",
                                marginLeft: '15px'
                              }} >
                              {/*<Link to={{
                            pathname : "/points-amount",
                            data : {Alias:item.donationCounterAlias,DisplayName:item.donationDisplayName}
                          }}>*/}
                              <a onClick={(e) => handleMerchantClick(e, item)}>
                                <p style={{ fontSize: '14px' }} className="DonationAreas">{item.donationDisplayName}</p>
                              </a>

                              {/*</Link>*/}
                            </div>
                          </div>
                          // </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
                {/* box ends */}
              </header>
            </BodyContainer>
            <Footer>
            </Footer>
          </Layout>
        </div>)}
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDonationOrganizationsAction: () => dispatch(fetchDonationOrganizationsAction())
  };
};

const mapStateToProps = (response) => {
  console.log(response);
  return {
    donateOrganaizations: response.DonateOrganizationReducer.donations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarPointDonates);
