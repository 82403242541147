import React from "react";

export class Dropdown extends React.Component {
    // selectValue => current selected value
    // filterData => choices in the dropdown
    // setFilteredData => function to change the selectValue
    
    handleChange = e => {
     this.props.setFilteredData(e.target.value);
    };
    
    render() {
       const { selectValue, filterData } = this.props;
       
       return (
            <div class="dropdown" style={{ height: '20px', width: '100px'}}>
            <select 
                value={selectValue} 
                onChange={this.handleChange}
             >
                  {filterData.map(data => {
                    return (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    );
                  })}
             </select>
            </div>
        
        );
     }
   }