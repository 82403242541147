import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import TermsAndConditions from "../TermsAndConditions/Pages/TermsAndConditions";

const TermsAndConditionsRoute = () => {
    return (
        <Fragment>
            <Route path="/terms" component={TermsAndConditions} />
        </Fragment>
    )
}

export default withRouter(TermsAndConditionsRoute)
