import ApiClient from "./ApiClient";

//To get current tier points
export const requestOTPService = async () => {

  let ENDPOINT = `core/send-auth-code-to-customer`;

  let params = {
    lob: sessionStorage.getItem('subscriberType')
  }
  
  let api = await ApiClient.post(
    ENDPOINT,
    params,
    {},
    (response) => {
      console.log("****** Request OTP response *******", response);
      return response
    }
  );

  return api;
};

export const makeDonation = async (request) => {

  let ENDPOINT = `core/transactions/donations-with-auth`;

  let api = await ApiClient.post(
    ENDPOINT,
    null,
    request.requestBody,
    (response) => {
      console.log("****** Make donation response *******", response);
      return response
    }
  );

  return api;
};

//To make bill payment
export const makeSelfRedemption = async (request) => {

  let ENDPOINT = `core/redemption-with-auth`;

  let api = await ApiClient.post(
    ENDPOINT,
    null,
    request.requestBody,
    (response) => {
      console.log("****** Make self redemption response *******", response);
      return response
    }
  );

  return api;
};

//To make bill payment
export const makeTransferPoints = async (request) => {
  console.log("points tranfer request body", request.requestBody)
  let ENDPOINT = `core/transactions/transfer-points-with-auth`;

  let api = await ApiClient.post(
    ENDPOINT,
    null,
    request.requestBody,
    (response) => {
      console.log("****** Make transfer points response *******", response);
      return response
    }
  );

  return api;
};