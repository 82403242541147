/* eslint-disable import/no-anonymous-default-export */
import * as types from '../Actions';

const initailState = {
    dialogOffers: {},
    merchantOffers: {},
    cxOffers: {},
    redeemOffer: {}
};

export default function (state = initailState, action) {
    let response = action.response;

    switch (action.type) {
        // Fetch dialog offers
        case types.GET_DIALOG_OFFERS_SUCCESS:
            return { ...state, dialogOffers: response };
        case types.GET_DIALOG_OFFERS_FAIL:
            return { ...state, dialogOffers: response };

        // Fetch merchant offers
        case types.GET_MERCHANT_OFFERS_SUCCESS:
            return { ...state, merchantOffers: response }
        case types.GET_MERCHANT_OFFERS_FAIL:
            return { ...state, merchantOffers: response }

        // Fetch merchant offers
        case types.GET_CX_OFFERS_SUCCESS:
            return { ...state, cxOffers: response }
        case types.GET_CX_OFFERS_FAIL:
            return { ...state, cxOffers: response }

        // Redeem offers
        case types.REDEEM_OFFER_SUCCESS:
            return { ...state, redeemOffer: response }
        case types.REDEEM_OFFER_FAIL:
            return { ...state, redeemOffer: response }

        default:
            return state;
    }
}