import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'

const Header = ({
    leftIcon,
    leftIconLink,
    headerTitle,
    rightIcon,
    rightIconLink,
}) => {

    let isNavigationBarHidden = sessionStorage.getItem('isNavBarHidden') === 'true';

    if (window.location.pathname === "/" && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('Home');
    } else if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('NotHome');
    }
    
    return (
        <header className={styles.header_container} style={{ backgroundColor: isNavigationBarHidden ? '#f4f4f8' : '#ffffff'}}>
            { !isNavigationBarHidden && leftIcon ?  <div className={styles.header_left}>
                { leftIconLink === "exitWebView" ?
                        (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            leftIcon ? <a href="#" onClick={() => {
                                window.ReactNativeWebView.postMessage('Exit');
                            }}>
                                <img
                                    className={styles.header_left__icon}
                                    src={leftIcon}
                                    alt="icon" />
                            </a>
                                : ""
                        )
                        :
                        (leftIcon ? <Link to={leftIconLink}>
                            <img
                                className={styles.header_left__icon}
                                src={leftIcon}
                                alt="icon" />
                        </Link>
                            : ""
                        )
                }
            </div> : null }
           
            <div className={styles.header_middle} style={{ width: rightIcon ? '70%' : leftIcon ? '85%' : '100%', justifyContent: isNavigationBarHidden || leftIcon ? 'left' : 'center', marginLeft: isNavigationBarHidden ? '6%' : '0%' }}>
                <span>{headerTitle}</span>
            </div>

            {rightIcon != null ? <div className={styles.header_right}>
                {rightIcon
                    ? <Link to={rightIconLink}>
                        <img
                            className={styles.header_right__icon}
                            src={rightIcon}
                            alt="icon" /> </Link>
                    : "" }
            </div> : null}

        </header>
    )
}

export default Header