import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import NegativeUserSenario from "./Pages/NegativeUserSenario";

const MessagePopupRoute = () => {
    return (
        <Fragment>
            <Route path="/userMessage"  component={NegativeUserSenario} />
        </Fragment>
    )
}

export default withRouter(MessagePopupRoute)
