import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import StarPointDonates from './Pages/StarPointDonates';

const StarPointDonateRoute = () => {
    return (
        <Fragment>
            <Route path="/Star-points-donate" component={StarPointDonates} />
        </Fragment>
    )
}

export default withRouter(StarPointDonateRoute)