import { put, call } from "redux-saga/effects";

import * as types from "../Actions";
import { fetchTierPointsService } from "../Services/tierPointsServices";

export function* fetchTierPointsSaga() {
  try {
    let response = yield call(fetchTierPointsService);
    if (response && response.success) {
      yield put({ type: types.FETCH_TIER_POINTS_SUCCESS, response });
    } else {
      yield put({ type: types.FETCH_TIER_POINTS_FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.FETCH_TIER_POINTS_FAIL, response: error });
  }
}
