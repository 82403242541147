import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import SelfRedemption from './Pages/SelfRedemption.js';

const SelfRedemptionRoute = () => {
    return (
        <Fragment>
            <Route path="/self-redemption" component={SelfRedemption} />
        </Fragment>
    )
}

export default withRouter(SelfRedemptionRoute)
