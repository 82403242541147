import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import OfferSearch from './Pages/SearchOffer';

const OfferSearhRoute = () => {
    return (
        <Fragment>
            <Route path="/offer-search" component={OfferSearch} />
        </Fragment>
    )
}

export default withRouter(OfferSearhRoute)
