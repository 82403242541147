import React, { useEffect, useState } from 'react';
import Layout from '../../../../Layout/Layout';
import { useHistory } from "react-router-dom";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import './DonatePoints.css';
import starPoints from "../../../../../Resources/shared/starpointsLogo.png";
import Footer from '../../../../UI/FooterArea/Footer';
import BodyContainer from '../../../../UI/BodyArea/Body';
import { connect } from "react-redux";
import firebase from 'firebase/app';
import "./../../../../../Common.scss";
import NumberFormat from 'react-number-format';

const DonateAmount = (props) => {
    const analytics = firebase.analytics();
    const [redeemablePoints, setRedeemablePoints] = useState("0");
    const [redeemablePointsDecimal, setRedeemablePointsDecimal] = useState("00");
    const [pointAmount, setPointAmount] = useState(0.0);
    const [donationDisabled, setDonationDisabled] = useState(true);
    const [redeemableAmountFloat, setRedeemableAmountFloat] = useState(0);
    const [merchantName, setMerchantName] = useState("");
    let history = useHistory();

    const splitDecmial = (value) => {
        let numArray = value.toString().split(".");
        return numArray;
    };

    const numberWithCommas = (x) => {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }

    const onPointValueChange = (event) => {

        let value = parseFloat(event.target.value.replaceAll(",", ""));
       
        if (value) {
            setPointAmount(value);
            if (value <= redeemableAmountFloat) {
                setDonationDisabled(false);
            } else {
                setDonationDisabled(true);
            }
        } else {
            setDonationDisabled(true);
        }
    }

    let donate = (event) => {
        console.log("donate started..");
        if (pointAmount && pointAmount < redeemableAmountFloat) {
            analytics.logEvent('star_p_donation_page_donate_btn_click', { donateAmount: pointAmount });
            sessionStorage.setItem("pointsAmount", pointAmount);
            sessionStorage.setItem("transacrionType", "DONATION");
            history.push('/otp-verification');
        }
    }

    useEffect(() => {
        console.log("use props", props)

        if (props.starPoints) {

            if (props.starPoints.DATA && props.starPoints.DATA.balance) {
                let numArray = splitDecmial(props.starPoints.DATA.balance.redeemableBalance);
                setRedeemablePoints((numArray[0]));

                setRedeemablePointsDecimal((numArray[1]) ? (numArray[1]).length === 1 ? (numArray[1]) + "0" : (numArray[1]) : "00");

                const donateMerchantDisplayName = sessionStorage.getItem("donateMerchantDisplayName");
                setMerchantName(donateMerchantDisplayName);

                //setting for common usage here
                let redeemablePointsFloat = parseFloat(props.starPoints.DATA.balance.redeemableBalance);
                setRedeemableAmountFloat(redeemablePointsFloat);
            } else {
                // error retrieving star points show error.
                console.log("NO POINTS!!!");
            }

        }

    }, [props.starPoints]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/Star-points-donate")
    }

    return (
            <div>
                <Layout
                    leftIcon={ArrowLeftIcon}
                    headerTitle={merchantName}
                    leftIconLink="/Star-points-donate"
                >
                    <BodyContainer>
                        <header className="App-container">
                            <div style={{ overflow: "hidden", width: "100%", backgroundColor: "#ffffff" }}>
                                <div className="Points-display" style={{ paddingTop: '25%' }}>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }} >
                                        Star Points
                                    </div>
                                    <div className="row" style={{ justifyContent: 'center', marginTop: '-1.5em' }}>
                                        <NumberFormat
                                            className="points-input"
                                            thousandSeparator={true}
                                            inputMode="decimal"
                                            name="points-input"
                                            onChange={(e) => onPointValueChange(e)}
                                            placeholder='0'
                                        />
                                    </div>
                                    <div className="row" style={{ fontSize: '15px', marginTop: "-70px", fontWeight: 'bold', display: 'flex', justifyContent: 'center', lineHeight: '16sp' }}>
                                        {isNaN(pointAmount) ?
                                            <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Please enter value</div> : ""}
                                        {pointAmount > redeemableAmountFloat ?
                                            <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Insufficient Points balance</div> : ""}
                                    </div>
                                </div>
                            </div>
                        </header>
                    </BodyContainer>
                </Layout>
                <Footer>
                    <div className="row" style={{ position: 'sticky', bottom: 0, left: 0, right: 0 }}>
                        <div className="col-12">
                            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 'medium' }}>
                                <img src={starPoints}
                                    style={{
                                        backgroundColor: 'white',
                                        width: '24px',
                                        height: '24px',
                                        marginRight: '10px',
                                        fontFamily: 'OpenSans'
                                    }} />
                                Redeemable Points : {numberWithCommas(redeemablePoints)}.
                                <span style={{ fontSize: '14px' }}>{redeemablePointsDecimal}</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <a onClick={(e) => donate(e)} style={{ color: 'white' }}>
                                <button className="btn-transaction remove-outline" disabled={donationDisabled}>
                                    DONATE
                                </button>
                            </a>
                        </div>
                    </div>
                </Footer>
            </div>
    );
}

const mapStateToProps = (response) => {
    console.log(response);
    return {
        starPoints: response.StarPointReducer.starPoints,
    };
};

export default connect(mapStateToProps)(DonateAmount);
