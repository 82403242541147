/* eslint-disable import/no-anonymous-default-export */
import * as types from '../Actions';

const initailState = {
    mobileNumber: {},
    connections: {},
    lob: {}
};

export default function (state = initailState, action) {
    let response = action.response;

    switch (action.type) {

        case types.GET_MOBILE_NUMBER_SUCCESS:
            return { ...state, mobileNumber: response };
        case types.GET_MOBILE_NUMBER_FAIL:
            return { ...state, mobileNumber: response };

        case types.GET_CONNECTIONS_UNDER_NIC_SUCCESS:
            return { ...state, connections: response };
        case types.GET_CONNECTIONS_UNDER_NIC_FAIL:
            return { ...state, connections: response };

        case types.GET_LOB_SUCCESS:
            return { ...state, lob: response };
        case types.GET_LOB_FAIL:
            return { ...state, lob: response };

        default:
            return state;
    }
}