import React, { useEffect, useState } from 'react';
import Layout from "../../../../../Layout/Layout";
import submitIcon from "../../../../../../Resources/shared/success_icon.svg";
import wrongIcon from "../../../../../../Resources/shared/wrong.png";
import BodyContainer from '../../../../../UI/BodyArea/Body';
import Footer from '../../../../../UI/FooterArea/Footer';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import {
	fetchStarpointsAction
} from "../../../../../../Actions/starpointsAction.js";
import firebase from 'firebase/app';
import "./../../../../../../Common.scss";

const DonateSubmit = (props) => {
	const analytics = firebase.analytics();
	const [status, setStatus] = useState("");
	const [points, setPoints] = useState("0");
	const [image, setImage] = useState(submitIcon);
	const [merchantName, setMerchantName] = useState("");
	const [accounts, setAccounts] = useState("0");
	let history = useHistory();

	const handleTransactionsClick = () => {
		analytics.logEvent('star_p_donation_success_trans_hist_click')
		history.push('/transaction-history')
	}

	const handleOkClick = () => {
		analytics.logEvent('star_p_donation_success_ok_btn_click')
		history.push('/')
	}

	useEffect(() => {
		if (props.transaction && props.transaction.DATA) {
			setAccounts(props.transaction.DATA.cardNumber);

			// Setting merchant data from the session storage
			const donateMerchantDisplayName = sessionStorage.getItem("donateMerchantDisplayName");
			setMerchantName(donateMerchantDisplayName);

			if (props.transaction.STATUS === "SUCCESS") {
				setPoints(props.transaction.DATA.amount);
				setStatus(props.transaction.DATA.transactionSerial);
				setImage(submitIcon);
			} else {
				setStatus("FAILED");
				setImage(wrongIcon);
			}
		} else {
			// Not enough data, possible error
			setStatus("FAILED");
			setImage(wrongIcon);
		}

	}, [props.transaction]);

	useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/")
    }
	
	return (
		<div>
			<div style={{ width: '100%' }}>
				<Layout
					headerTitle="Donation submitted"
				>
					<BodyContainer>
						<div className="flex-container" style={{ justifyContent: 'center', alignItems: 'center' }}>
							<div className="img-submit">
								<img src={image} style={{ width: '72px', height: '72px' }} />
							</div>
							<div className="flex-item" id="donated-helpage" style={{ fontSize: '16px', color: 'black' }}>
								You have donated for {merchantName}
							</div>
							<div className="flex-item" style={{ fontSize: '24px', fontWeight: 'bold' }}>
								{points} <span style={{ fontWeight: 'normal' }}>points</span>
							</div>
							<div className="flex-item" style={{ fontSize: '14px', fontWeight: 'OpenSans' }}>
								Using Star Points : <span style={{ fontWeight: 'bold' }}>{accounts.replace(/(\d{3})\d{3}/, "$1***")}</span>
							</div>
							<hr />
							<div className="flex-item" style={{ color: '#767676', fontWeight: 'bold', fontSize: '14px', lineHeight: '24px' }}>
								Transaction ID
								<div style={{ fontWeight: 'bold', color: 'black' }}> {status}</div>
							</div>
						</div>
					</BodyContainer>
					<Footer>
					<div className="row" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="col">
                                    <div style={{ fontSize: '12px', color: '#767676', textAlign: 'center' }}>
                                        You will receive a notification once the transaction
                                        <div>is completed</div>
                                    </div>
                                </div>
                            </div>
						<div className="row" style={{}}>
							<div className="col">
								<a style={{ color: 'white' }} onClick={handleTransactionsClick}>
									<button className="btn-next remove-outline" style={{ width: '100%', backgroundColor: 'white', color: 'black', fontSize: '12px', padding: '2%' }}>
										TRANSACTIONS
									</button>
								</a>
							</div>
							<div className="col">
								<a style={{ color: 'white' }} onClick={handleOkClick}>
									<button className="btn-next remove-outline">
										OK
									</button>
								</a>
							</div>
						</div>
					</Footer>

				</Layout>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchStarpointsAction: () => dispatch(fetchStarpointsAction())
	};
};

const mapStateToProps = (response) => {
	console.log(response);
	return {
		transaction: response.AuthTransactionReducer.transactions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DonateSubmit);
