import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import StarLoyaltyInfo from './Pages/StarLoyaltyInfo';

const StarLoyaltyInfoRoute = () => {
    return (
        <Fragment>
           <Route path="/star-loyalty-info" component={StarLoyaltyInfo} />
        </Fragment>
    )
}

export default withRouter(StarLoyaltyInfoRoute)