import ApiClient from "./ApiClient";

export const getDonateOrganizationService = async () => {
  let ENDPOINT = `core/donation-organizations`;
 
  let api = await ApiClient.get(
    ENDPOINT,
    null,
    (response) => {
      console.log("****** Get donor list response *******", response);
      return response
    }
  );

  return api;

  };
