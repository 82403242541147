/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import Layout from "../../../Layout/Layout";
import "./TermsAndConditions.scss";
import { useHistory } from "react-router-dom";
import BodyContainer from "../../../UI/BodyArea/Body";

const TermsAndConditions = (props) => {

  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    history.push("/")
  }

  return (
    <Fragment>
      <Layout
        leftIcon={ArrowLeftIcon}
        headerTitle="Terms &amp; conditions"
        leftIconLink="/"
      >
        <BodyContainer>
          <header className="App-container">
            <div className="card card-3">
              <div className="flex-container">
                <div className="color-light-black" style={{ fontSize: '15px', fontWeight: 'medium' }} >
                  <div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber has the right to request for Star Points from any of the partnering Merchants.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Subscriber shall be awarded Star Points for any active (postpaid and prepaid) mobile, broadband (home broadband and mobile broadband) and Dialog TV connection.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Star Points shall be awarded based on the Subscriber’s monthly bill payment or top up as and when the Subscriber settles the Dialog bills and/or tops up.</div>
                      <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}></span>The following payments shall be excluded: Bill payments made via Star Points, Device purchase, Lesi Pay Device instalment, Share credit (recipient), Loans (will be awarded for the reload from which the loan will be recovered from), any free payment / top up/ any rewards (Mega Wasana, Star rewards, etc.), any Bonus Reloads, any bonus payments, Cash back, waivers (waivers which are added as payments not as bill adjustments), credit notes.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Star Points shall be awarded for any active connection under a registered National Identity Card Number (NIC). Star Points shall not be awarded for Corporate and Enterprise Connections.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber shall be awarded 1% of their payment value as Star Points for every (as an example only, 1 Star Point shall be awarded for Rs. 100/-) payment / top up made subject to the exclusions mentioned in clause 3 above. Club Vision member’s primary connection shall be awarded the percentage allocated as per their Club Vision tier.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>At the time of Redemption, the Subscriber should maintain a minimum balance of 100 points.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>One (01) point is equivalent to Rs.1/-(One Rupee).</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Star Points shall have a validity period of one (01) year from the date of accumulation and shall expire in two cycles at the end of June and December every year after the validity period.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Subscriber shall be responsible to provide the correct Dialog Subscriber Mobile Number and shall check and confirm the transaction details with the Merchant.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>For Redemption and Reversal of Transactions, Subscriber should provide the Authentication Code to the Merchant.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Subscriber shall request for Reversals within five (05) working days from the transaction date. If not, the Merchant shall not do any Reversals. If the transaction date has been exceeded by more than five (05) working days, Subscriber shall not be entitled for any Reversals.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber may request to Redeem the accumulated points as long as the Subscriber’s Dialog mobile connection is active and shall also be able to request for Reversals as long as the Subscriber’s Dialog mobile connection is active. (Example: If the Connection is one way barred or connection is active, then the Subscriber can do Redemptions or Reversals. However, if the connection is two way barred or permanently disconnected, then the Subscriber shall not be able to do Redemptions or Reversals).</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber may inquire about his/her Star Points balance from the Merchant. In addition to this service, the Subscriber can also inquire the balance and offers via MyDialog App, Genie App, Star Points App and by dialing #141#, typing Star Points and sending a SMS to 141.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber shall indemnify Dialog for whatsoever liability arising out of the Subscriber being a member of the Star Points Program.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Subscriber shall immediately inform any loss of the mobile phone with the mobile connection to Dialog. Dialog shall not be liable for any transactions carried out fraudulently during such time period.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber shall indemnify and keep Dialog fully indemnified from and against all actions, claims, demands, cost, expenses, liabilities, loss, damages or other monetary relief brought, made or awarded against or incurred by Dialog (directly or indirectly) arising out of any transaction carried out by the Subscriber with the Merchant.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog or any of its respective suppliers does not make any representations or warranties, express or implied, including without limitation any warranty of merchantability or fitness for a particular purpose, with respect to any of the services furnished by the Merchant or Dialog.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog shall be entitled to suspend or terminate a Subscriber from the Star Points loyalty scheme at its discretion.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The Subscriber shall adhere to the policies, mandatory provisions and guidelines relating to Anti Bribery and Corruption located at https://www.dialog.lk which may be amended from time to time.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog shall process any personal data the Subscriber provides in accordance with the Privacy Notice located at https://www.dialog.lk/privacy-notice</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog shall be entitled to from time to time amend and vary the terms and conditions mentioned herein.</div>
                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                      <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>In the event of any inconsistency/dispute between the English terms and conditions and the Sinhala / Tamil terms and conditions, the English translation shall prevail to the extent of the conflict.</div>
                  </div>

                </div>

              </div>
            </div>
          </header>
        </BodyContainer>
      </Layout>
      )
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const mapStateToProps = (response) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);