/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import styles from "../../Header/Header.module.scss";

const MessageTemplate = ({
    leftIcon,
    leftIconLink,
    headerContentMessage,
    bodyMessage,
    mainImage,
    actionText,
    actionLink,
    marginTop
}) => {

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        window.ReactNativeWebView.postMessage('Exit');
    }


    return (
        <div className="container styles.messageTemplate_margin">

            {/* Header row of the message template */}
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="col-2">
                    <a onClick={() => {
                        window.ReactNativeWebView.postMessage('Exit');
                    }}>
                        <img
                            className={styles.messageTemplate_icon}
                            src={leftIcon}
                            alt="icon" />
                    </a>
                </div>
                <div className="col-10">
                    <p className={styles.messageTemplate_headerMessage}>{headerContentMessage}</p>
                </div>
            </div>

            {/* Header row of the message template */}
            <div className="row" style={{ marginTop: '-10px' }}>
                <div className="col-12">
                    <p className={styles.messageTemplate_bodyMessage}>
                        {bodyMessage}
                    </p>
                </div>
            </div>

            {/* Image row of the message template */}
            <div className="row">
                <div className="col-12" style={{ textAlign: "center" }}>
                    <img
                        className={marginTop ? styles.messageTemplate_mainImageWithMargin : styles.messageTemplate_mainImage}
                        src={mainImage}
                        alt="icon" />
                </div>
            </div>

            {/* Black action button of the message template */}
            <div className="row" style={{ position: "absolute", bottom: 0, width: '100%', }}>
                <div className="col-12">
                    {
                        actionLink === "exit" ?
                            <button className={styles.mainBtn}
                                onClick={() => { window.ReactNativeWebView.postMessage('Exit'); }}>
                                {actionText}
                            </button>
                            :
                            <a href="tel:141"><button className={styles.mainBtn}>{actionText}</button></a>
                    }

                </div>
            </div>

        </div>
    )
}

export default MessageTemplate;
