import { put, call } from 'redux-saga/effects';

import * as types from "../Actions";
import {
    getDialogOffersService,
    getMerchantOffersService,
    getCxOffersService,
    redeemOffersService
} from '../Services/offersService';

export function* getDialogOffersSaga(request) {
    try {
        let response = yield call(getDialogOffersService, request);
        if(response && response.success) {
            yield put({ type: types.GET_DIALOG_OFFERS_SUCCESS, response });
        } else {
            yield put({ type: types.GET_DIALOG_OFFERS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.GET_DIALOG_OFFERS_FAIL, response: error });
    }
}

export function* getMerchantOffersSaga(request) {
    try {
        let response = yield call(getMerchantOffersService, request);
        if(response && response.success) {
            yield put({ type: types.GET_MERCHANT_OFFERS_SUCCESS, response });
        } else {
            yield put({ type: types.GET_MERCHANT_OFFERS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.GET_MERCHANT_OFFERS_FAIL, response: error });
    }
}

export function* getCxOffersSaga() {
    try {
        let response = yield call(getCxOffersService);
        if(response && response.success) {
            yield put({ type: types.GET_CX_OFFERS_SUCCESS, response });
        } else {
            yield put({ type: types.GET_CX_OFFERS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.GET_CX_OFFERS_FAIL, response: error });
    }
}

export function* redeemOffersSaga(request) {
    try {
        let response = yield call(redeemOffersService, request);
        if(response && response.success) {
            yield put({ type: types.REDEEM_OFFER_SUCCESS, response });
        } else {
            yield put({ type: types.REDEEM_OFFER_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.REDEEM_OFFER_FAIL, response: error });
    }
}
