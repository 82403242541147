/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./ConnectionsUnderNicOverlay.module.scss";
import xImage from "../../../../../Resources/shared/close_icon.svg";
import dialog from "../../../../../Resources/transfer/dialog_mobile.png";
import homeBroadBand from "../../../../../Resources/transfer/HBB.svg";
import television from "../../../../../Resources/transfer/DTV.svg";
import otherConnection from "../../../../../Resources/transfer/other_account.svg";
import foodCity from "../../../../../Resources/transfer/FOOD_CITY.svg";
import radioButtonSelected from "../../../../../Resources/shared/radio_button_selected.svg";
import radioButtonUnSelected from "../../../../../Resources/shared/radio_button_unselected.svg";
import infoIcon from "../../../../../Resources/shared/info.svg";
import "./../../../../../Common.scss";
const ConnectionsUnderNic = (props) => {

  const [array, setArray] = useState([]);
  const [selectedConnection, setSelectedConnection] = useState("");

  const close = (value) => {
    props.handleCLose(value);
  };

  const handleProviderClick = (event, item) => {
    sessionStorage.setItem("connectionReference", item.connectionReference);
    sessionStorage.setItem("lob", item.lob);
    setSelectedConnection(item.connectionReference);
  }

  useEffect(() => {

    if (props.connections) {
      if (props.connections.DATA.starPointConnections) {
        setSelectedConnection(sessionStorage.getItem("connectionReference"));
        let connections = props.connections.DATA.starPointConnections;
        setArray(connections);
      }
    }

  }, [props.connections, props.array]);

  const getTextFromConnectionNumber = (connectionNumber) => {

    if (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
      return 'Cargills'.concat(" ", connectionNumber.replace(/(\d{0})\d{4}/, "$1****"));
    } else {
      return connectionNumber;
    }
  }

  const getLogoFromLob = (lob, connectionNumber) => {

    switch (lob) {
      case 'GSM':
        return dialog;
      case 'HBB':
        return homeBroadBand;
      case 'DTV':
        return television;
      default:
        if (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
          return foodCity;
        } else {
          return otherConnection;
        }
    }

  }

  return (
    <div className={styles.overlay}>
      <div className={styles.overlay_card}>
        <div className={styles.overlay_card_content}>
          <div className={styles.overlay_card_content_header}>
            <div className={styles.overlay_card_content_header_left}>
              <div className="row" style={{ height: '40px' }}>
                <div className="col-3 no-padding">
                  <img src={dialog} alt="logo" className="avatar" />
                </div>
                <div className="col no-padding">
                  <div className="row no-padding" style={{ display: 'flex', textAlign: "left", marginLeft: '-4px', marginTop: '-15px', fontSize: '20px', fontWeight: 'bold' }}>
                    <div>Star Points accounts</div>
                    <div>under your NIC</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.overlay_card_content_header_right}
              onClick={close.bind(close, false)}>
              <img
                src={xImage}
                className=""
                alt="logo"
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: '35px', height: '100px', backgroundColor: "#f9f9f9", borderBottom: "1px solid #edeff5" }}>
            <div className="col-3 no-padding" >
              <img src={infoIcon} alt="arrow-icon" className="logo" style={{ marginLeft: '16px', padding: '1px' }} />
            </div>
            <div className="col no-padding" style={{ display: 'flex', textAlign: "left", marginLeft: '-15px', fontSize: '14px' }} >To transfer points to a Star Points account, the account should be under your NIC.</div>
          </div>


          <div className="flex-container no-padding" style={{ width: '100%', height: '300px', overflowY: 'auto', overflowX: 'hidden', marginTop: '0px' }}>
            {
              array.filter(connection => connection.connectionReference !== sessionStorage.getItem("mobileNumber")).map((item, index) => (
                <a onClick={(e) => handleProviderClick(e, item)}>
                  <div
                    key={index}
                    className="row"
                    style={{ marginTop: '20px', height: '80px', backgroundColor: "#f9f9f9", borderBottom: "1px solid #edeff5" }}
                  >
                    <div className="col no-padding">
                      <img src={getLogoFromLob(item.lob, item.connectionReference)} alt="logo" className="avatar" />
                    </div>
                    <div className="col-6 no-padding"
                      style={{
                        display: 'flex',
                        textAlign: "left",
                        fontSize: '14px'
                      }} >{getTextFromConnectionNumber(item.connectionReference)}</div>
                    <div className="col">
                      <img src={item.connectionReference === selectedConnection ? radioButtonSelected : radioButtonUnSelected} className="arrow-icon" alt="logo" style={{ marginLeft: '90%' }} />
                    </div>
                  </div>
                </a>
              ))
            }
          </div>

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (response) => {
  return {
    connections: response.MobileNumberReducer.connections,
  };
};

export default connect(mapStateToProps)(ConnectionsUnderNic);
