import React from 'react';

export default function CirculerLoading() {
    return (
    <div className="" role="status">
        {/*<span className="sr-only">Loading...</span>*/}
        <img src="https://d397hqg6fxi8os.cloudfront.net/loading_icon.gif" className="loading-gif" alt="logo" />
        <p className="loading-gif-text">Loading...</p>
    </div>);
}
