import React, { useEffect, useState } from 'react';
import Layout from "../../../../Layout/Layout";
import submitIcon from "../../../../../Resources/shared/success_icon.svg";
import wrongIcon from "../../../../../Resources/shared/wrong.png";
import BodyContainer from '../../../../UI/BodyArea/Body';
import Footer from '../../../../UI/FooterArea/Footer';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import "./../../../../../Common.scss";
import firebase from 'firebase/app';

const SelfRedemptionSuccess = (props) => {
    const analytics = firebase.analytics();
    const [status, setStatus] = useState("");
    const [points, setPoints] = useState("0");
    const [pointsDecimal, setPointsDecimal] = useState("0");
    const [image, setImage] = useState(submitIcon);
    const [subscriberValue, setSubscriberValue] = useState("");
    let history = useHistory();

    const handleTransactionsClick = () => {
        analytics.logEvent('star_p_redemption_success_trans_hist_click')
        history.push('/transaction-history')
    }

    const handleOkClick = () => {
        analytics.logEvent('star_p_redemption_success_ok_btn_click')
        history.push('/')
    }

    const splitDecmial = (value) => {
        let numArray = value.toString().split(".");
        return numArray;
    };

    const numberWithCommas = (x) => {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }

    useEffect(() => {
        if (props.redemption) {
            if (props.redemption) {
                // Setting subscriberValue data from the session storage

                const pointsAmount = sessionStorage.getItem("pointsAmount");

                let numArray = splitDecmial(
                    pointsAmount
                );

                setPoints(numArray[0] ? numArray[0] : "0");
                setPointsDecimal(numArray[1] ? (numArray[1]) : "00");

                if (props.redemption.STATUS === "SUCCESS") {
                    setSubscriberValue(props.redemption.DATA.accountNo);
                    setStatus(props.redemption.DATA.transactionId);
                    setImage(submitIcon);
                } else {
                    setStatus("FAILED");
                    setImage(wrongIcon);
                }
            } else {
                // Not enough data, possible error
                console.log("Not enough data, possible error")
                setStatus("FAILED");
                setImage(wrongIcon);
            }

        } else {
            // Error No Transaction detected.
            console.log("Error No Transaction detected")
            setStatus("FAILED");
            setImage(wrongIcon);
        }

    }, [props.transaction]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/")
    }

    const getNameByLob = () => {

        switch (sessionStorage.getItem('subscriberType')) {
            case "MOBILE":
                return "Dialog Mobile"
            case "HBB":
                return "Dialog HBB"
            case "DTV":
                return "Dialog TV"
            default:
                return "Dialog Mobile"
        }

    }

    return (
            <div>
                <div style={{ width: '100%' }}>
                    <Layout
                        headerTitle="Payment successful"
                    >
                        <BodyContainer>
                            <div className="flex-container" style={{ marginLeft: '20px', marginRight: '20px' }}>
                                <div className="img-submit">
                                    <img src={image} style={{ width: '72px', height: '72px', marginTop: '3rem' }} alt="logo" />
                                </div>
                                <div className="flex-item" id="donated-helpage" style={{ fontSize: '16px', color: 'black' }}>You have paid for {getNameByLob()}</div>
                                <div className="flex-item" style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                    <span className="font-medium font-size-24">
                                        Rs. {numberWithCommas(points)}
                                    </span>
                                    <span className="font-medium font-size-24">{`.${pointsDecimal}${pointsDecimal === 0 ? 0 : ""
                                        }`}</span>
                                </div>
                                <div className="flex-item" style={{ fontSize: '14px', fontWeight: 'OpenSans' }}>
                                    Using Star Points : <span style={{ fontWeight: 'bold' }}>{ subscriberValue.replace(/(\d{3})\d{3}/, "$1***") }</span>
                                </div>
                                <hr></hr>
                                <div className="row no-padding">
                                    <div className="col">
                                        <div className="font-medium flex-item align-left" style={{ mariginLeft: 'left', justifyContent: 'left', alignItems: 'left', color: '#767676', fontSize: '14px', lineHeight: '24px' }}>
                                            Connection number
                                            <div style={{ color: 'black', mariginLeft: 'left', justifyContent: 'left' }}>{subscriberValue}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="font-medium flex-item align-right" style={{ justifyContent: 'right', alignItems: 'right', color: '#767676', fontSize: '14px', lineHeight: '24px' }}>
                                            Transaction ID
                                            <div style={{ color: 'black', justifyContent: 'right', alignItems: 'right' }}> {status}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </BodyContainer>
                        <Footer>
                            <div className="row" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="col">
                                    <div style={{ fontSize: '12px', color: '#767676', textAlign: 'center' }}>
                                        You will receive a notification once the payment
                                        <div>is completed</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{}}>
                                <div className="col">
                                    <a style={{ color: 'white' }} onClick={handleTransactionsClick}>
                                        <button className="btn-next remove-outline" style={{ width: '100%', backgroundColor: 'white', color: 'black', fontSize: '12px', padding: '2%' }}>
                                            TRANSACTIONS
                                        </button>
                                    </a>
                                </div>
                                <div className="col">
                                    <a style={{ color: 'white' }} onClick={handleOkClick}>
                                        <button className="btn-next remove-outline">
                                            OK
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </Footer>
                    </Layout>
                </div>
            </div>
    );
}

const mapStateToProps = (response) => {
    console.log(response);
    return {
        redemption: response.AuthTransactionReducer.redemption,
    };
};

export default connect(mapStateToProps)(SelfRedemptionSuccess);
