import * as Types from './index';

export const getMobileNumberAction = () => {
    return {
        type: Types.GET_MOBILE_NUMBER
    }
}

export const getConnectionUnderNicAction = () => {
    return {
        type: Types.GET_CONNECTIONS_UNDER_NIC
    }
}

export const getLobForConnectionAction = () => {
    return {
        type: Types.GET_LOB
    }
}