import { put, call } from "redux-saga/effects";

import * as types from "../Actions";
import { fetchTransactionsService } from "../Services/transactionServices";

export function* fetchTransactionsSaga() {
  try {
    let response = yield call(fetchTransactionsService);
    if (response && response.success) {
      yield put({ type: types.FETCH_TRANSACTIONS_SUCCESS, response });
    } else {
      yield put({ type: types.FETCH_TRANSACTIONS_FAIL, response });
    }
  } catch (error) {
    yield put({ type: types.FETCH_TRANSACTIONS_FAIL, response: error });
  }
}
