import React from "react";
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";

import StarPointRoute from '../components/Modules/TransactionsHistory/StarPoints.route';
import StarPointsHomeRoute from '../components/Modules/StarPointsHome/StarPointsHome.Route';
import StarPointDonateRoute from '../components/Modules/StartPointsDonate/StarPointsDonate.Route';
import DonateAmountRoute from "../components/Modules/StartPointsDonate/DonateAmount/DonateAmount.Route";
import DonateSubmitRoute from "../components/Modules/StartPointsDonate/DonateAmount/DonateSubmitPage/DonateSubmit.Route";
import TermsAndConditionsRoute from "../components/Modules/TermsAndConditions/TermsAndConditions.route";
import MessagePopupRoute from "../components/Modules/NegativeUserScenario/MessagePopup.route";
import SelfRedemptionRoute from "../components/Modules/SelfRedemption/SelfRedemption.Route";
import SelfRedemptionConfirmationRoute from "../components/Modules/SelfRedemption/Confirmation/SelfRedemptionConfirmation.Route";
import SelfRedemptionSuccessRoute from "../components/Modules/SelfRedemption/RedemptionSuccess/RedemptionSuccess.Route";
import StarLoyaltyRoute from '../components/Modules/StarLoyalty/StarLoyalty.Route';
import StarLoyaltyInfoRoute from '../components/Modules/StarLoyalty/StarLoyaltyInfo/StarLoyaltyInfo.Route';
import OTPVerificationRoute from '../components/Modules/OTPVerification/OTPVerification.Route';
import StarPointsTransferRoute from '../components/Modules/StarPointsTransfer/StarPointsTransfer.Route';
import TransferPointsRoute from '../components/Modules/StarPointsTransfer/TransferPoints/TransferPoints.Route';
import TransferPointsConfirmationRoute from '../components/Modules/StarPointsTransfer/Confirmation/TransferConfirmation.Route';
import TansferSuccessRoute from '../components/Modules/StarPointsTransfer/TransferSuccess/TransferSuccess.Route';
import OfferCategoriesRoute from '../components/Modules/Offers/Categories/OfferCategories.Route';
import OffersRoute from '../components/Modules/Offers/Offers.Route';
import OfferDetailRoute from '../components/Modules/Offers/OfferDetail/OfferDetail.Route';
import OfferSearchRoute from '../components/Modules/Offers/OfferSearch/SearchOffer.Route';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <React.Fragment>
                    <StarPointRoute />
                    <StarPointsHomeRoute />
                    <StarPointDonateRoute />
                    <DonateAmountRoute />
                    <DonateSubmitRoute />
                    <TermsAndConditionsRoute />
                    <MessagePopupRoute />
                    <SelfRedemptionRoute />
                    <SelfRedemptionConfirmationRoute />
                    <SelfRedemptionSuccessRoute />
                    <StarLoyaltyRoute />
                    <StarLoyaltyInfoRoute />
                    <OTPVerificationRoute />
                    <StarPointsTransferRoute />
                    <TransferPointsRoute />
                    <TransferPointsConfirmationRoute />
                    <TansferSuccessRoute />
                    <OffersRoute />
                    <OfferCategoriesRoute />
                    <OfferDetailRoute />
                    <OfferSearchRoute />
                </React.Fragment>
            </Switch>
        </Router>
    );
}

export default Routes;
