import * as Types from './index';

export const getDialogOffersAction = (request) => {
    return {
        type: Types.GET_DIALOG_OFFERS,
        request
    }
}

export const getMerchantOffersAction = (request) => {
    return {
        type: Types.GET_MERCHANT_OFFERS,
        request
    }
}

export const getCxOffersAction = () => {
    return {
        type: Types.GET_CX_OFFERS
    }
}

export const redeemOfferAction = (request) => {
    return {
        type: Types.REDEEM_OFFER,
        request
    }
}