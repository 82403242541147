import React, { Fragment } from 'react';

function Footer(props){
    const appStyle={
        width:'100%',
        position:'fixed',
        bottom:'0',
        zIndex:'1',
        //margin:'1%',
        left:0,
        paddingLeft:'2%',
        backgroundColor:'#f9f9f9',
        borderTopLeftRadius : '16px',
        borderTopRightRadius : '16px',

    }
    return(
        <Fragment>
                <div style={appStyle}>
                    {props.children}
                </div>

        </Fragment>

    );
}
export default Footer;
