/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-duplicate-case */
import * as types from '../Actions';

const initailState = {
    otp: {},
    redemption: {},
    donate: {},
    transferPoints: {}
};

export default function (state = initailState, action) {
    let response = action.response;

    switch (action.type) {

        case types.REQUEST_OTP_SUCCESS:
            return { ...state, otp: response };
        case types.REQUEST_OTP_FAIL:
            return { ...state, otp: response };

        case types.MAKE_SELF_REDEMPTION_SUCCESS:
            return { ...state, redemption: response };
        case types.MAKE_SELF_REDEMPTION__FAIL:
            return { ...state, redemption: response };

        case types.FETCH_ORGANIZATIONS_DONATE_SUCCESS:
            return { ...state, transactions: response };
        case types.FETCH_ORGANIZATIONS_DONATE_FAIL:
            return { ...state, transactions: response };

        case types.MAKE_TRASFER_POINTS_SUCCESS:
            return { ...state, transferPoints: response };
        case types.MAKE_TRASFER_POINTS_FAIL:
            return { ...state, transferPoints: response };

        default:
            return state;
    }
}
