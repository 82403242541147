import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import offerCategories from './Pages/OfferCategories';

const OfferCategoriesRoute = () => {
    return (
        <Fragment>
            <Route path="/offer-categories" component={offerCategories} />
        </Fragment>
    )
}

export default withRouter(OfferCategoriesRoute)
