import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import StarPoints from './Pages/StarPoints';

const StartPointsRoute = (props) => {
    return (
        <Fragment>
            <Route path="/transaction-history"  component={StarPoints} />
        </Fragment>
    )
}

export default withRouter(StartPointsRoute)
