import React from 'react'
import styled from 'styled-components'

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  border-radius: 18px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px #e0e8ea, 0 6px 6px #e0e8ea;
`

const ThumbContainer = styled.div`
  position: relative;
`

export const Thumb = styled.img`
  width: 100%;
  object-fit: cover;
  height: 229px;
  background-color: white;
  border-radius: 18px;
`

export const OfferCategoryContainer = styled.div`
  width: 160px;
  height: 35px;
  position: absolute;
  margin-top: -34px;
  background-color: white;
  border-radius: 0px 30px 0px 18px;
`
export const OfferCategoryInnerContainer = styled.div`
  display: flex;
  width: 154px;
  height: 29px;
  margin: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 29px 0px 17px;
`

const OfferCategoryTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: white;
`

const Title = styled.span`
  font-size: 16px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 600;
  color: #333333;
`

const Validity = styled.span`
    width: 160px;
    height: 24px;
    margin-top: 10px;
    margin-bottom: 25px;
    display: flex;
    margin-left: 15px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    `

export const MenuItem = props => (
  <MenuItemContainer>
    <ThumbContainer>
      <Thumb style= {{ height: (window.innerWidth - 32) / 1.5 }} src={props.thumb} />
      {/* <OfferCategoryContainer>
        <OfferCategoryInnerContainer style={{ backgroundColor: props.category === 'myLinuxOffers' ?  '#00c2bc' : '#f4af6a' }}>
          <OfferCategoryTitle>{props.category === 'myLinuxOffers' ? 'Star Points' : 'Star Rewards'}</OfferCategoryTitle>
        </OfferCategoryInnerContainer>
      </OfferCategoryContainer> */}
    </ThumbContainer>
    <Title>{props.title}</Title>
    <Validity style={{ width: props.isMyLinxOffers ? '220px' : '160px', backgroundColor: props.isMyLinxOffers ? '#caf8fa' : props.isOfferAvailable ? '#6ed40040' : 'lightGrey', color: props.isMyLinxOffers ? '#2ab4de' : props.isOfferAvailable ? '#333333' : '#ffffff' }}>{props.isMyLinxOffers ? props.validity : props.isOfferAvailable ? 'Available for redeem' : 'Offer redeemed'}</Validity>
  </MenuItemContainer>
)
