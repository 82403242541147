import * as Types from './index';

export const requestOTPAction = () => {
    return {
        type: Types.REQUEST_OTP
    }
}

export const makeDonationAction = (requestBody) => {
     return {
        type: Types.FETCH_ORGANIZATIONS_DONATE,
        requestBody
    }
}

export const makeSelfRedemptionAction = (requestBody) => {
    return {
        type: Types.MAKE_SELF_REDEMPTION,
        requestBody
    }
}

export const makeTransferPointsAction = (requestBody) => {
    return {
        type: Types.MAKE_TRASFER_POINTS,
        requestBody
    }
}