/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import Layout from "../../../Layout/Layout";
import { useHistory } from "react-router-dom";

import styled from 'styled-components'
import { useScroll } from 'react-use-gesture'
import { useSpring } from 'react-spring'
import { MIN_HEADER_HEIGHT, SCROLL_OFFSET } from '../Pages/Constants'
import { GlobalStyle } from '../Pages/GlobalStyles'
import { Section } from './Section'
import { MenuItem } from './MenuItem'
import { Subtitle } from './Subtitle'
import searchIcon from "../../../../Resources/shared/search_icon.svg"

const AppContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #f4f4f8;
`

const Content = styled.div`
  overflow-y: auto;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f4f4f8;
  ::-webkit-scrollbar {display:none;}
`

const TabContent = styled.div`
  z-index: 15;
  position: fixed;
  top: 56px;
  width: 95%;
  height: 43px;
  flex-grow: 1;
  display:flex;
  margin: 8px;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {display:none;}
`
const Tab = styled.span`
  height: 30px;
  display: flex;
  margin: 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 450;
  border-radius: 15px;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px
`

const Offers = (props) => {

  let history = useHistory();

  const tabScrollAnchors = useRef(props.cxOffers.DATA.carecategory_list.map(_ => 0))

  // eslint-disable-next-line no-unused-vars
  const [{ y }, setScrollPos] = useSpring(() => ({ y: 0 }))
  const [{ activeTabIndex }, setActiveTabIndex] = useSpring(() => ({
    activeTabIndex: parseInt(sessionStorage.getItem("CATEGORY-INDEX")),
  }))

  const [horizontalTabPositions, setHorizontalTabPositions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(parseInt(sessionStorage.getItem("CATEGORY-INDEX")));
  const [isAutoScrollDisbled, setIsAutoScrollDisbled] = useState(false);
  const contentContainer = useRef(null)
  const tabContainer = useRef(null)

  const bind = useScroll(({ xy }) => {

    if (!isAutoScrollDisbled) {
      setScrollPos({ y: xy[1], immediate: true })

      setActiveTabIndex({
        activeTabIndex: tabScrollAnchors.current.reduce(
          (acc, curr, i) =>
            xy[1] + MIN_HEADER_HEIGHT + SCROLL_OFFSET >= curr ? i : acc,
          0
        ),
      })

      let currentIndex = parseInt(activeTabIndex.value);
      setActiveIndex(currentIndex);
      scrollToTabPosition(currentIndex);
      //console.log("current index", activeTabIndex.value);
    }

  })

  const scrollToTabPosition = (index) => {
    tabContainer.current.scrollTo(horizontalTabPositions[index], 0);
    // tabContainer.current.scrollTo({
    //   left: horizontalTabPositions[index],
    //   behavior: 'smooth'
    // });
  }

  const freezeAutoScroll = () => {
    setIsAutoScrollDisbled(true);

    setTimeout(() => {
      setIsAutoScrollDisbled(false);
    }, 3000);

  }

  const handleTabClick = (index) => {
    freezeAutoScroll()
    setActiveIndex(index);
    scrollToTabPosition(index);
    contentContainer.current.scrollTo({
      top: tabScrollAnchors.current[index] - MIN_HEADER_HEIGHT - SCROLL_OFFSET + 80,
      behavior: 'smooth'
    });
  }

  const handleOfferClick = (item) => {
    console.log("clicked offer", item);
    history.push({
      pathname: '/offer-detail',
      state: { offer: item, isFrom: 'LIST' }
    })
  }

  const handleSectionLayout = (index, offset) => {
    tabScrollAnchors.current[index] = offset.top
  }

  function getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    context.font = font || getComputedStyle(document.body).font;
  
    return context.measureText(text).width;
  }

  useEffect(() => {

    var currentOffSet = 0
    setHorizontalTabPositions([0])
    let tabPositions = [0]
    
    props.cxOffers.DATA.carecategory_list.map((category) => {
      let length = getTextWidth(category.category_name, 18) + 70;
      let offSet = currentOffSet + length
      tabPositions.push(offSet);
      currentOffSet += length;
    });

    //console.log("tabPositions size", tabPositions);
    setHorizontalTabPositions(tabPositions);

    let selectedCategoryIndex = parseInt(sessionStorage.getItem("CATEGORY-INDEX"));
    freezeAutoScroll();
    contentContainer.current.scrollTo(0, tabScrollAnchors.current[selectedCategoryIndex] - MIN_HEADER_HEIGHT - SCROLL_OFFSET + 80);

    tabContainer.current.scrollTo(tabPositions[selectedCategoryIndex] - 100, 0);

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    sessionStorage.setItem('OFFER_SEARCH_NAVIGATION', 'OFFER_LISTING');

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

  }, [props.cxOffers]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    history.push("/offer-categories")
  }

  return (

    <AppContainer>
      <GlobalStyle />
      <Layout
        leftIcon={ArrowLeftIcon}
        rightIcon={searchIcon}
        headerTitle="Offers"
        leftIconLink="/offer-categories"
        rightIconLink="/offer-search" />
      <TabContent ref={tabContainer}>
        {props.cxOffers.DATA.carecategory_list.map(({ category_name }, i) => (
          <a key={i} onClick={(e) => handleTabClick(i)}>
            <Tab style={{ width: 'auto', backgroundColor: activeIndex === i ? '#f2daea' : '#e8e8e8', color: activeIndex === i ? '#7b1b66' : '#3d3b3b', border: '#7b1b66', borderWidth: activeIndex === i ? '1.5px' : '0px', borderStyle: 'solid' }}>{category_name}</Tab>
          </a>
        ))}
      </TabContent>
      <Content {...bind()} ref={contentContainer}>
        {props.cxOffers.DATA.carecategory_list.map(({ category_name, offer_list }, i) => (
          <Section key={category_name} index={i} onLayout={handleSectionLayout}>
            <Subtitle>{category_name}</Subtitle>
            {offer_list.map((offer, i) => (
              <a key={i} onClick={(e) => handleOfferClick(offer)}>
                <MenuItem
                  key={offer.offer_title}
                  title={offer.offer_title}
                  thumb={offer.offer_category === 'myLinuxOffers' ? offer.offer_banner : offer.image_url}
                  validity={offer.validity}
                  category={offer.offer_category}
                  isMyLinxOffers={offer.offer_category === 'myLinuxOffers'}
                  isOfferAvailable={offer.validity === 'Available'}
                  onClick={handleOfferClick}
                />
              </a>
            ))}
          </Section>
        ))}
      </Content>
    </AppContainer>
  );
};

const mapStateToProps = (response) => {
  return {
    cxOffers: response.OffersReducer.cxOffers,
  };
};

export default connect(mapStateToProps)(Offers);
