import React, { Fragment } from 'react';
import {
    Route,
    withRouter
} from 'react-router-dom';

import StarPointsHome from './Pages/StarPointsHome';

const StarPointsHomeRoute = (props) => {
    return (
        <Fragment>
            <Route path="/" exact component={StarPointsHome} />
        </Fragment>
    )
}

export default withRouter(StarPointsHomeRoute)
