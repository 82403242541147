import * as types from '../Actions';

const initailState = {
    starPoints: {}
};

export default function (state = initailState, action) {
    let response = action.response;
    
    switch (action.type) {
        // Fetch starpoints
        case types.FETCH_STARPOINTS_SUCCESS:
            return { ...state, starPoints: response };
        case types.FETCH_STARPOINTS_FAIL:
            return { ...state, starPoints: response };

        default:
            return state;
    }
}