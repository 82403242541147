/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Fragment } from "react";
import Layout from "../../../../Layout/Layout";
import BodyContainer from "../../../../UI/BodyArea/Body";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../../../../Common.scss";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import DropDownIcon from "../../../../../Resources/offers/iconDropDown.svg";
import transationEmptyIcon from "../../../../../Resources/transfer/transaction_empty.svg";
import { OfferItem } from './OfferItem'
import styled from 'styled-components'
import { Dropdown } from './DropDown'

const AppContainer = styled.div`
    overflow-x: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
`

const Content = styled.div`
  overflow-y: auto;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f4f4f8;
  ::-webkit-scrollbar {display:none;}
`

const TabContent = styled.div`
  z-index: 15;
  position: fixed;
  top: 56px;
  width: 100%;
  height: 120px;
  flex-grow: 1;
  display:flex;
  flex-direction: column;
  background-color: #ffffff;
`

const OfferCategories = (props) => {

    let history = useHistory();

    const [offers, setOffers] = useState([]);
    const [searchArray, setSearchArray] = useState([]);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [selectedSrotingOption, setSelectedSrotingOption] = useState("Newest");

    let sortOptions = ["Newest", "Oldest"];

    const handleBackButton = () => {

        let isFrom = sessionStorage.getItem('OFFER_SEARCH_NAVIGATION');
        console.log("OFFER_SEARCH_NAVIGATION", isFrom);
        switch (isFrom) {
            case 'OFFER_CATEGORIES':
                return '/offer-categories';
            case 'OFFER_LISTING':
                return '/offers';
            default:
                return '/offer-categories';

        }
    }

    const handleOfferClick = (event, item) => {
        history.push({
            pathname: '/offer-detail',
            state: { offer: item, isFrom: 'SEARCH' }
        })
    }


    useEffect(() => {

        let allOffers = []
        if (props.cxOffers.STATUS === 'SUCCESS') {

            if (props.cxOffers.DATA.carecategory_list && props.cxOffers.DATA.carecategory_list.length > 0) {
                props.cxOffers.DATA.carecategory_list.map((category) => {
                    category.offer_list.map((offer) => {
                        allOffers.push(offer);
                    });
                });
    
                allOffers.sort(function (a, b) {
                    return new Date(b.offer_start_date) - new Date(a.offer_start_date);
                });
    
                setOffers(allOffers);
                setSearchArray(allOffers);
            }
        }

    }, [props.cxOffers]);

    const onOptionsSelected = (name) => {
        setSelectedSrotingOption(name);

        var allOffers = searchArray;

        if (name === "Newest") {
            allOffers.sort(function (a, b) {
                return new Date(b.offer_start_date) - new Date(a.offer_start_date);
            });
        } else {
            allOffers.sort(function (a, b) {
                return new Date(a.offer_start_date) - new Date(b.offer_start_date);
            });
        }

        setSearchArray(allOffers);
    };

    const handleChange = (event) => {
        event.preventDefault();

        const { name, value } = event.target;
        setSearchKeyWord(value);
        if (name === "searchInputField") {

            let allOffers = [];

            if (value === "" || value === null) {
                allOffers = offers;
            } else {
                allOffers = offers.filter(offer => offer.offer_title.toUpperCase().includes(value.toUpperCase()) || (offer.merchant_name && offer.merchant_name.toUpperCase().includes(value.toUpperCase())));
            }

            if (selectedSrotingOption === "Newest") {
                allOffers.sort(function (a, b) {
                    return new Date(b.offer_start_date) - new Date(a.offer_start_date);
                });
            } else {
                allOffers.sort(function (a, b) {
                    return new Date(a.offer_start_date) - new Date(b.offer_start_date);
                });
            }

            setSearchArray(allOffers);
        }
    }

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push(handleBackButton())
    }

    return (
        <AppContainer>
            <Fragment>
                <Layout
                    leftIcon={ArrowLeftIcon}
                    headerTitle="Search"
                    leftIconLink={handleBackButton}
                >
                    <BodyContainer>
                        <header className="App-container">
                            <div style={{ width: "100%", backgroundColor: "#ffffff" }}>

                                <TabContent>
                                    <div className="inputIcons" style={{ paddingTop: '20px' }}>
                                        <i className="fa fa-search icon" style={{ paddingTop: '10px', paddingBottom: '10px' }}></i>
                                        <input className={"inputField"}
                                            name="searchInputField"
                                            onChangeCapture={handleChange}
                                            type="text"
                                            style={{ paddingTop: '10px', paddingBottom: '10px' }}
                                            placeholder="Search Offers" />
                                    </div>

                                    <div className='row no-margin' style={{ marginTop: '-10px', color: '#4a4a4a', fontSize: "14px", fontWeight: '500' }}>
                                        <div className="col-3 no-padding" style={{ marginTop: '1px', }}> Sort by</div>
                                        <div className="col-3 no-padding" style={{ marginLeft: '-30px' }}>
                                            <Dropdown
                                                selectValue={selectedSrotingOption}
                                                filterData={sortOptions}
                                                setFilteredData={onOptionsSelected}
                                            /></div>
                                        <div className="col no-padding" >
                                            <img src={DropDownIcon} alt="arrow-icon" className="logo" style={{ height: '12px', width: '12px', marginLeft: '-25px' }} />
                                        </div>
                                    </div>
                                </TabContent>

                                <Content>

                                    {searchArray.map((offer, i) => (
                                        <a key={i} onClick={(e) => handleOfferClick(e, offer)}>
                                            <OfferItem
                                                key={offer.offer_title}
                                                title={offer.offer_title}
                                                thumb={offer.offer_category === 'myLinuxOffers' ? offer.offer_banner : offer.image_url}
                                                validity={offer.validity}
                                                category={offer.offer_category}
                                                isMyLinxOffers={offer.offer_category === 'myLinuxOffers'}
                                                isOfferAvailable={offer.validity === 'Available'}
                                                onClick={handleOfferClick}
                                            />
                                        </a>

                                    ))}

                                    {
                                        (searchArray.length === 0) ?
                                            <div className="no-data-container">
                                                <img src={transationEmptyIcon} alt="Logo" />
                                                <div className="empty-heading">
                                                    Sorry no match found for {searchKeyWord}
                                                </div>
                                            </div> : ""
                                    }
                                </Content>

                            </div>
                        </header>
                    </BodyContainer>

                </Layout>
            </Fragment>
        </AppContainer>
    );
};

const mapStateToProps = (response) => {
    return {
        cxOffers: response.OffersReducer.cxOffers,
    };
};

export default connect(mapStateToProps)(OfferCategories);
