import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import configureStore from "./Store/configureStore";
import "bootstrap/dist/css/bootstrap.css";

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#ffffff",
      dark: "#a5acbc",
      contrastText: "#ae3737",
    },
    secondary: {
      light: "#ff7961",
      main: "#333333",
      dark: "#ba000d",
      contrastText: "#000",
    },
    headingOffFocus: {
      light: "#cccccc",
      main: "#cccccc",
      dark: "#cccccc",
      contrastText: "#cccccc",
    },
    headingFocused: {
      light: "#333333",
      main: "#333333",
      dark: "#333333",
      contrastText: "#333333",
    },
    transparenta: {
      light: "rgba(0,0,0,0)",
      main: "#333333",
      dark: "#333333",
      contrastText: "#333333",
    },
    background: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#ffffff",
      contrastText: "#ffffff",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();