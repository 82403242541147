import React from 'react'
import styled from 'styled-components'
import { withContentRect } from 'react-measure'

const MenuSection = styled.section`
`
const MeasuredSection = withContentRect('offset')(
  ({ measureRef, ...props }) => <MenuSection ref={measureRef} {...props} />
)

export const Section = ({ onLayout, index, ...props }) => {
  const handleResize = ({ offset }) => {
    if (offset) {
      onLayout(index, offset)
    }
  }
  return <MeasuredSection onResize={handleResize} {...props} />
}
