import React from "react";
import Routes from "./Bootstrap/Routes";
import "./App.scss";

import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyBDATUpHdAdVkSfi_GIh3PBXviYqlKT134",
    authDomain: "starpoints-dev.firebaseapp.com",
    databaseURL: "https://starpoints-dev.firebaseio.com",
    projectId: "starpoints-dev",
    storageBucket: "starpoints-dev.appspot.com",
    messagingSenderId: "187402340124",
    appId: "1:187402340124:web:a367052bb5b146ecef9cd9",
    measurementId: "G-KZH1DXD9NW"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
  return (
    <React.Fragment>
      <Routes/>
    </React.Fragment>
  );
}

export default App;
