import ApiClient from "./ApiClient";

//To get mobile number by passig token
export const getMobileNumberService = async () => {

  let ENDPOINT = `core/subscriber/accountByToken`;
  
  let api = await ApiClient.get(
    ENDPOINT,
    null,
    (response) => {
      console.log("****** Get mobile number response *******", response);
      return response
    }
  );
  return api;
};


//To get connection user nic
export const getConnectionUnderNicSevice = async () => {

  let ENDPOINT = `core/transactions/connections-by-ref`;
  
  let api = await ApiClient.post(
    ENDPOINT,
    null,
    null,
    (response) => {
      console.log("****** Get connection under nic response *******", response);
      return response
    }
  );
  return api;
};


//To get lob
export const getLobForConnectionService = async () => {

  let ENDPOINT = `core/transactions/get-lob`;
  
  let api = await ApiClient.get(
    ENDPOINT,
    null,
    (response) => {
      console.log("****** Get connection under nic response *******", response);
      return response
    }
  );
  return api;
};