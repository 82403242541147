import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import TansferSuccess from './Pages/TansferSuccess.js';

const TansferSuccessRoute = () => {
    return (
        <Fragment>
            <Route path="/transfer-points-success" component={TansferSuccess} />
        </Fragment>
    )
}

export default withRouter(TansferSuccessRoute)
