import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import OTPVerification from './Pages/OTPVerification.js';

const OTPVerificationRoute = () => {
    return (
        <Fragment>
            <Route path="/otp-verification" component={OTPVerification} />
        </Fragment>
    )
}

export default withRouter(OTPVerificationRoute)
