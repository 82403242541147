import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout/Layout';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import starPoints from "../../../../Resources/shared/starpointsLogo.png";
import Dialog from "../../../../Resources/transfer/dialog_mobile.svg";
import Footer from '../../../UI/FooterArea/Footer';
import BodyContainer from '../../../UI/BodyArea/Body';
import "./../../../../Common.scss";
import errorImg from "../../../../Resources/shared/error.svg";
import Modal from "react-modal";
import NumberFormat from 'react-number-format';

const SelfRedemption = (props) => {
    const [redeemablePoints, setRedeemablePoints] = useState("0");
    const [redeemablePointsDecimal, setRedeemablePointsDecimal] = useState("00");
    const [pointAmount, setPointAmount] = useState(0.0);
    const [redeemDisabled, setRedeemDisabled] = useState(true);
    const [redeemableAmountFloat, setRedeemableAmountFloat] = useState(0);
    const [showModel, setShowModel] = useState(false);
    let history = useHistory();

    const handleOpenModal = () => {
        setShowModel(false);
    }
    const handleCloseModal = () => {
        setShowModel(false);
    }
    const afterOpenModal = () => {
    }

    const customStyles = {
        overlay: {
            zIndex: '30000',
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        content: {
            width: '90%',
            top: 'auto',
            left: '5%',
            right: '5%',
            bottom: '30%',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '20px',
            color: '#000000)',
            padding: '10px',
            textAlign: 'center'
        }
    };

    const handleNextClick = () => {
        sessionStorage.setItem("pointsAmount", pointAmount);
        history.push('/self-redemption-confirmation')
    }

    const splitDecmial = (value) => {
        let numArray = value.toString().split(".");
        return numArray;
    };

    const numberWithCommas = (x) => {
        const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return value;
    }

    const onPointValueChange = (event) => {
        let value = parseFloat(event.target.value.replaceAll(",", ""));
      
        if (value) {
            setPointAmount(value);
            if (value <= redeemableAmountFloat) {
                setRedeemDisabled(false);
            } else {
                setRedeemDisabled(true);
            }
        } else {
            setRedeemDisabled(true);
        }
        
    }

    useEffect(() => {

        if (props.starPoints) {
            if (props.starPoints.DATA && props.starPoints.DATA.balance) {
                let numArray = splitDecmial(props.starPoints.DATA.balance.redeemableBalance);
                setRedeemablePoints((numArray[0]));

                setRedeemablePointsDecimal((numArray[1]) ? (numArray[1]).length === 1 ? (numArray[1]) + "0" : (numArray[1]) : "00");

                //setting for common usage here
                let redeemablePointsFloat = parseFloat(props.starPoints.DATA.balance.redeemableBalance);
                setRedeemableAmountFloat(redeemablePointsFloat);
            }
        }

    }, [props.starPoints]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/")
    }

    return (
            <div>
                <Layout
                    leftIcon={ArrowLeftIcon}
                    rightIcon={Dialog}
                    headerTitle="Pay / Reload"
                    leftIconLink="/"
                >
                    <BodyContainer>
                        <header className="App-container">
                            <div style={{ overflow: "hidden", width: "100%", backgroundColor: "#ffffff" }}>
                                <div className="Points-display" style={{ paddingTop: '25%' }}>
                                    <div style={{ fontSize: '14px', fontWeight: 'medium', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }} >
                                        Star Points
                                    </div>
                                    <div className="row" style={{ justifyContent: 'center', marginTop: '-1.5em' }}>
                                        <NumberFormat
                                            className="points-input"
                                            thousandSeparator={true}
                                            inputMode="decimal"
                                            name="points-input"
                                            onChange={(e) => onPointValueChange(e)}
                                            placeholder='0'
                                        />
                                    </div>

                                    <div className="row" style={{ fontSize: '15px', marginTop: "-70px", fontWeight: 'bold', display: 'flex', justifyContent: 'center', lineHeight: '16sp' }}>
                                        {isNaN(pointAmount) ?
                                            <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Please enter value</div> : ""}
                                        {pointAmount > redeemableAmountFloat ?
                                            <div className='error' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Insufficient Points balance</div> : ""}
                                    </div>
                                </div>

                            </div>
                            <div className="col">
                                <hr className="hr-dashed"></hr>
                                <h4 className="color-black" style={{ fontWeight: 'medium', display: 'flex', justifyContent: 'center', alignItems: 'center', lineHeight: '8sp' }}>
                                    <span className="font-regular font-size-16">{sessionStorage.getItem("mobileNumber")}</span>
                                </h4>
                                <hr className="hr-dashed"></hr>
                            </div>
                        </header>
                    </BodyContainer>
                </Layout>
                <Footer>
                    <div className="row" style={{ position: 'sticky', bottom: 0, left: 0, right: 0 }}>
                        <div className="col-12">
                            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: 'medium' }}>
                                <img src={starPoints}
                                    style={{
                                        backgroundColor: 'white',
                                        width: '24px',
                                        height: '24px',
                                        marginRight: '10px',
                                        fontFamily: 'OpenSans'
                                    }} />
                                Redeemable Points : {numberWithCommas(redeemablePoints)}.
                                <span style={{ fontSize: '14px' }}>{redeemablePointsDecimal}</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <a onClick={handleNextClick} style={{ color: 'white' }}>
                                <button className="btn-transaction remove-outline" disabled={redeemDisabled}>NEXT</button>
                            </a>
                        </div>
                    </div>


                    <div>
                        <Modal
                            isOpen={showModel}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={handleCloseModal}
                            style={customStyles}
                            contentLabel="Error Modal"
                        >
                            <div className="container content">
                                <div className="popUp">
                                    <div className="row justify-content-center">
                                        <img src={errorImg} alt="Logo" />
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="justify-content-center" style={{ width: '65%' }}>
                                            <h6 className="errorModalMainText">
                                                Transaction failed, try again
                                            </h6>
                                        </div>

                                    </div>
                                    <div className="row justify-content-center" style={{ marginTop: '-2.3em' }}>
                                        <div className="justify-content-center" style={{ width: '100%' }}>
                                            <h6 className="errorModalSubText">
                                                Sorry!! We encounter an error while
                                                processing your donation
                                            </h6>
                                        </div>

                                    </div>
                                    <div className="justify-content-center" style={{ borderTop: '2px solid #eceef4', marginLeft: '-2em', marginRight: '-2em' }}>
                                        <p className="popUpActionText" onClick={handleOpenModal}>
                                            CLOSE
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>

                </Footer>
            </div>
    );
}

const mapStateToProps = (response) => {
    return {
        starPoints: response.StarPointReducer.starPoints,
    };
};

export default connect(mapStateToProps)(SelfRedemption);
