import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import DonateAmount from './Pages/DonateAmount';

const DonatedAmount = () => {
    return (
        <Fragment>
            <Route path="/points-amount" component={DonateAmount} />
        </Fragment>
    )
}

export default withRouter(DonatedAmount)