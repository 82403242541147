/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import dialogIcon from "../../../../Resources/transfer/dialog_mobile.png";
import dialog from "../../../../Resources/transfer/dialog_mobile.svg";
import homeBroadBand from "../../../../Resources/transfer/HBB.svg";
import television from "../../../../Resources/transfer/DTV.svg"
import foodCity from "../../../../Resources/transfer/FOOD_CITY.svg";
import otherConnection from "../../../../Resources/transfer/other_account.svg";
import transationEmptyIcon from "../../../../Resources/transfer/transaction_empty.svg";
import {
    getConnectionUnderNicAction,
} from "../../../../Actions/mobileNumberAction";
import Layout from "../../../Layout/Layout";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BodyContainer from '../../../UI/BodyArea/Body';
import CircularLoading from "../../../Common/CirculerLoading";
import Footer from '../../../UI/FooterArea/Footer';
import "./../../../../Common.scss";
import firebase from 'firebase/app';

const StarPointsTransfer = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [array, setArray] = useState([]);
    let history = useHistory();

    const loadingStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    };

    const handleProviderClick = (event, item) => {
        sessionStorage.setItem("connectionReference", item.connectionReference);
        sessionStorage.setItem("lob", item.lob);
        history.push('/transfer-points')
    }

    useEffect(() => {
        props.getConnectionUnderNicAction();
    }, []);

    useEffect(() => {

        if (props.connections) {

            if (props.connections.STATUS) {
                setIsLoading(false);
                if (props.connections.STATUS === 'SUCCESS') {
                    let connections = props.connections.DATA.starPointConnections;
                    setArray(connections);
                }

            }
        }

    }, [props.connections]);

    const getLogoFromLob = (lob, connectionNumber) => {

        switch (lob) {
            case 'GSM':
                return dialogIcon;
            case 'HBB':
                return homeBroadBand;
            case 'DTV':
                return television;
            default:
                if (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
                    return foodCity;
                } else {
                    return otherConnection;
                }
        }
    }

    const getTextFromConnectionNumber = (connectionNumber) => {

        if (connectionNumber.startsWith('88') || connectionNumber.startsWith('89') || connectionNumber.startsWith('87') || connectionNumber.startsWith('90')) {
            return 'Cargills'.concat(" ", connectionNumber.replace(/(\d{0})\d{4}/, "$1****"));
        } else {
            return connectionNumber;
        }
    }

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/")
    }

    return (
        <Fragment>
            {isLoading ? (
                <div style={loadingStyles}>
                    <CircularLoading />
                </div>
            ) : (
                <div style={{ width: '100%' }}>
                    <Layout
                        leftIcon={ArrowLeftIcon}
                        rightIcon={dialog}
                        headerTitle="Star Points Transfer"
                        leftIconLink="/"
                    >
                        <BodyContainer>
                            <header className="container">
                                <div style={{ width: "100%", backgroundColor: "#ffffff" }}>


                                    <div className="row">
                                        <p style={{ color: '#767676', fontWeight: 'bold' }}>Star Points accounts under your NIC</p>
                                    </div>

                                    {
                                        (array.length > 0) ?
                                            <div
                                                className="row"
                                                style={{
                                                    backgroundColor: "#f9f9f9",
                                                    borderRadius: "16px",
                                                    marginTop: "-20px"
                                                }}
                                            >
                                                <div className="flex-container no-padding" style={{ marginTop: -15 }}>
                                                    {
                                                        array.filter(connection => connection.connectionReference !== sessionStorage.getItem("mobileNumber")).map((item, index) => (
                                                            <a key={index} onClick={(e) => handleProviderClick(e, item)}>
                                                                <div
                                                                    key={index}
                                                                    className="row"
                                                                    style={{ height: "80px", marginTop: "15px", borderBottom: "1px solid #edeff5" }}>
                                                                    <div className="col-3 no-padding">
                                                                        <img src={getLogoFromLob(item.lob, item.connectionReference)} key={item.lob} alt="logo" className="avatar" />
                                                                    </div>
                                                                    <div className="col-6"
                                                                        style={{
                                                                            textAlign: "left",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            marginLeft: '15px'
                                                                        }} >

                                                                        <div style={{ fontSize: '14px' }} className="DonationAreas">{getTextFromConnectionNumber(item.connectionReference)}</div>
                                                                    </div>
                                                                </div>

                                                            </a>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            : null}

                                    {
                                        (array.length === 0) ?
                                            <div className="no-data-container">
                                                <img src={transationEmptyIcon} alt="Logo" />
                                                <div className="empty-heading">
                                                    Sorry no Star Points accounts found
                                                </div>
                                            </div> : ""
                                    }
                                </div>
                                {/* box ends */}
                            </header>
                        </BodyContainer>
                        <Footer>
                        </Footer>
                    </Layout>
                </div>)}
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getConnectionUnderNicAction: () => dispatch(getConnectionUnderNicAction()),
    };
};

const mapStateToProps = (response) => {
    return {
        connections: response.MobileNumberReducer.connections,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarPointsTransfer);
