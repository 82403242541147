import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import SelfRedemptionConfirmation from './Pages/SelfRedemptionConfirmation.js';

const SelfRedemptionConfirmationRoute = () => {
    return (
        <Fragment>
            <Route path="/self-redemption-confirmation" component={SelfRedemptionConfirmation} />
        </Fragment>
    )
}

export default withRouter(SelfRedemptionConfirmationRoute)
