import ApiClient from "./ApiClient";

//To get current tier points
export const fetchTierPointsService = async() => {
    let ENDPOINT = `core/loyalty-details`;
   
    let api = await ApiClient.get(
      ENDPOINT,
      null,
      (response) => {
        console.log("****** Get loyalty detais response *******", response);
        return response
      }
    );

    return api;

  };
 
  