import { put, call } from 'redux-saga/effects';

import * as types from "../Actions";
import {
    fetchStarpointsService
} from '../Services/starpointServices';

export function* fetchStarpointsSaga() {
    try {
        let response = yield call(fetchStarpointsService);
        if(response && response.success) {
            yield put({ type: types.FETCH_STARPOINTS_SUCCESS, response });
        } else {
            yield put({ type: types.FETCH_STARPOINTS_FAIL, response });
        }
    } catch (error) {
        yield put({ type: types.FETCH_STARPOINTS_FAIL, response: error });
    }
}
