import { put, call } from 'redux-saga/effects';

import * as types from "../Actions";
import {getDonateOrganizationService} from '../Services/donateOrganizationService';

export function* donateOrganizationSaga() {
   
    try {
        
        let response = yield call(getDonateOrganizationService)
       
        if(response && response.success) {
            yield put({ type: types.FETCH_DONATION_ORGANIZATIONS_SUCCESS, response })
        } else {
            yield put({ type: types.FETCH_DONATION_ORGANIZATIONS_FAIL, response })
        }
    } catch (error) {
        yield put({ type: types.FETCH_DONATION_ORGANIZATIONS_FAIL, response: error })
    }
}