import ApiClient from "./ApiClient";

//To get dialog offers
export const getDialogOffersService = async (request) => {
  let ENDPOINT = `offer/subscriber`;

  let params = {
    type: request.request.connectionType,
    lob: request.request.lob,
    tier: sessionStorage.getItem("tierLevel")
  }

  let api = await ApiClient.get(
    ENDPOINT,
    params,
    (response) => {
      console.log("****** Get dialog offers response *******", response);
      return response
    }
  );

  return api;

};

//To get merchant offers
export const getMerchantOffersService = async (request) => {
  let ENDPOINT = `offer/merchant-offers`;

  let params = {
    merchantOfferType: request.request.merchantOfferType,
  }

  let api = await ApiClient.get(
    ENDPOINT,
    params,
    (response) => {
      console.log("****** Get merchant offers response *******", response);
      return response
    }
  );

  return api;

};

//To cx offers
export const getCxOffersService = async () => {
  let ENDPOINT = `core/cx-offers`;

  let request = {
    loyaltyType: sessionStorage.getItem("tierLevel"),
  }

  let api = await ApiClient.post(
    ENDPOINT,
    null,
    request,
    (response) => {
      console.log("****** Get Cx offers response *******", response);
      return response
    }
  );

  return api;

};

//To redeem offers
export const redeemOffersService = async (request) => {
  let ENDPOINT = `core/my-linux/redeem`;

  console.log("redeem requesrt", request);
  let api = await ApiClient.post(
    ENDPOINT,
    null,
    request.request,
    (response) => {
      console.log("****** Get Redeem offers response *******", response);
      return response
    }
  );

  return api;

};
