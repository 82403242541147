/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import firebase from 'firebase/app';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CircularLoading from "../../../Common/CirculerLoading";
import donate from "../../../../Resources/home/donate_points.svg";
import offers from "../../../../Resources/home/offers.svg";
import offersLoading from "../../../../Resources/home/offersLoading.gif";
import transfer from "../../../../Resources/home/transfer_points.svg";
import starPoints from "../../../../Resources/shared/starpoint.png";
import redeemLogo from "../../../../Resources/shared/dialog_pay.png";
import threeStarLogo from "../../../../Resources/home/three_star_logo.svg";
import fiveStarLogo from "../../../../Resources/home/five_star_logo.svg";
import sevenStarLogo from "../../../../Resources/home/seven_star_logo.svg";
import starWhite from "../../../../Resources/home/star_white.svg";
import myqr from "../../../../Resources/home/myqr.svg";
import termsandcon from "../../../../Resources/home/terms_and_conditions.svg";
import userIcon from "../../../../Resources/shared/user_icon.svg";
import ArrowLeftIcon from "../../../../Resources/shared/arrow_left.svg";
import ArrowRightIcon from "../../../../Resources/shared/arrow_right_purple.svg";
import ArrowRightIconGray from "../../../../Resources/shared/arrow_right_gray.svg";
import Layout from "../../../Layout/Layout";
import "./starPointHome.scss";
import "./../../../../Common.scss";
import Footer from "../../../UI/FooterArea/Footer";
import BodyContainer from "../../../UI/BodyArea/Body";

import {
  fetchStarpointsAction,
} from "../../../../Actions/starpointsAction";
import {
  fetchTierPointsAction,
} from "../../../../Actions/tierPointsAction";
import {
  getMobileNumberAction,
  getConnectionUnderNicAction,
  getLobForConnectionAction
} from "../../../../Actions/mobileNumberAction";
import {
  getCxOffersAction,
} from "../../../../Actions/offersAction";

const loadingStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

const StarPointsHome = (props) => {

  const analytics = firebase.analytics();

  const [isLoading, setIsLoading] = useState(true);
  const [redeemablePoints, setRedeemablePoints] = useState("0");
  const [redeemablePointsDecimal, setRedeemablePointsDecimal] = useState("0");
  const [totalPoints, setTotalPoints] = useState("0");
  const [totalPointsDecimal, setTotalPointsDecimal] = useState("0");
  const [pointsExpire, setpointsExpire] = useState("0");
  const [pointsExpireDecimal, setpointsExpireDecimal] = useState("0");
  const [expireDate, setExpireDate] = useState("");
  const [transactionHistoryText, setTransactionHistoryText] = useState("0");
  const [showLastTrans, setShowLastTrans] = useState(true);
  const [tierLevelIcon, setTierLevelIcon] = useState(null);
  const [isTierPointsAvailable, setIsTierPointsAvailable] = useState(false);
  const [isTierPointsLoading, setIsTierPointsLoading] = useState(true);
  const [isMobileNumberHidden, setIsMobileNumberHidden] = useState(true);
  const [isStarPointsLoading, setIsStarPointsLoading] = useState(true);
  const [isOfferLoaded, setIsOfferLoaded] = useState(false);
  const [popularOffers, setPopularOffers] = useState([]);
  const [isDialogNumber, setIsDialogNumber] = useState(false);
  const [isOfferAvailable, setIsOfferAvailable] = useState(true);
  let history = useHistory();

  const splitDecmial = (value) => {
    let numArray = value.toString().split(".");
    return numArray;
  };

  const numberWithCommas = (x) => {
    const value = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  }

  const logViewRecentTransactionHistory = () => {
    analytics.logEvent('star_p_home_view_btn_click')
  }

  const logViewTransactionHistory = () => {
    analytics.logEvent('star_p_home_transactions_btn_click')
  }

  const logViewHomePage = () => {
    analytics.logEvent('star_p_home_view_screen')
  }

  const logDonationPage = () => {
    console.log("logging analytics : star_p_donate_points_button_click_home");
    analytics.logEvent('star_p_home_donate_points_btn_click')
  }

  const handlePointDonationClick = () => {
    if (!isStarPointsLoading) {
      logDonationPage();
      history.push('/Star-points-donate')
    }
  }

  const handlePointTransferClick = () => {
    if (!isStarPointsLoading) {
      history.push('/star-points-transfer')
    }
  }

  const handleViewTransactionClick = () => {
    logViewTransactionHistory();
    history.push('/transaction-history')

  }

  const handleViewRecentTransactionClick = () => {
    logViewRecentTransactionHistory();
    history.push('/transaction-history')
  }

  const handleSelfRedemptionClick = () => {
    if (!isStarPointsLoading) {
      history.push('/self-redemption')
    }
  }

  const handleOffersClick = () => {
    if (isOfferLoaded) {
      sessionStorage.setItem("STAR-TIER-OFFERS-PATH", "HOME");
      history.push("/offer-categories")
    }
  }

  const handleLoyaltyClick = () => {
    history.push('/star-loyalty')
  }

  const handleOferClick = (event, item) => {
    history.push({
      pathname: '/offer-detail',
      state: { offer: item, isFrom: 'HOME' }
    })
  }

  useEffect(() => {

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    }

  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.ReactNativeWebView.postMessage('Exit');
  }

  useEffect(() => {

    const interval = setInterval(() => {
      console.log("checking for token");
      if (sessionStorage.getItem("token")) {
        props.getMobileNumberAction();
        clearInterval(interval);
      }
    }, 500);
  }, []);

  useEffect(() => {

    if (props.mobileNumber.STATUS === 'SUCCESS') {

      let mobileNumber = props.mobileNumber.DATA.accountNo;
      setIsMobileNumberHidden(false);
      sessionStorage.setItem("mobileNumber", mobileNumber);
      sessionStorage.setItem("otpReceipient", mobileNumber);

      props.getLobForConnectionAction();

    }
  }, [props.mobileNumber]);

  useEffect(() => {

    let status = props.lob.STATUS;

    if (status) {

      setIsLoading(false);

      if (status === 'SUCCESS') {

        let mobileNumber = sessionStorage.getItem('mobileNumber');
        let lob = props.lob.DATA.subscriberType;

        if (/7[7|6|4][0-9]{7}/.test(mobileNumber) || lob === 'HBB' || lob === 'DTV') {
          setIsDialogNumber(true);
        } else {
          sessionStorage.setItem("tierLevel", "LOYALTY-TYPE-NOT-FOUND");
          props.getCxOffersAction();
        }

        sessionStorage.setItem("subscriberType", 'MOBILE');

        let otpReceipient = props.lob.DATA.contactNumber
        sessionStorage.setItem("otpReceipient", otpReceipient ? otpReceipient : mobileNumber);

      } else {
        sessionStorage.setItem("subscriberType", 'MOBILE');
      }

      props.fetchStarpointsAction();
      props.fetchTierPointsAction();

    }

  }, [props.lob]);


  useEffect(() => {

    if (props.starPoints.STATUS === 'FAILED') {
      history.push('/userMessage')
    }

    if (props.starPoints.STATUS &&
      props.starPoints.STATUS === "SUCCESS" &&
      props.starPoints.DATA &&
      (props.starPoints.DATA.statusCode === 1002 || props.starPoints.DATA.statusCode === 1004)
    ) {

      setIsStarPointsLoading(false);

      let numArray = splitDecmial(
        props.starPoints.DATA.balance.currentBalance
      );

      setTotalPoints(numArray[0] ? numArray[0] : "0");
      setTotalPointsDecimal(numArray[1] ? (numArray[1]) : "00");

      numArray = splitDecmial(
        props.starPoints.DATA.balance.redeemableBalance
      );

      setRedeemablePoints((numArray[0]));
      setRedeemablePointsDecimal(numArray[1] ? (numArray[1]) : "00");
      numArray = splitDecmial(
        props.starPoints.DATA.balance.expiredAmount
      );

      setpointsExpire((numArray[0]));

      if (numArray[1] && numArray[1].length === 1) {
        numArray[1] = numArray[1] + "0";
      }

      setpointsExpireDecimal(numArray[1] ? (numArray[1]) : "00");

      setExpireDate(
        props.starPoints.DATA.balance.expiredDate
      );

      if (props.starPoints.DATA && props.starPoints.DATA.transactions && props.starPoints.DATA.transactions.length > 0 && props.starPoints.DATA.transactions[0].amount > 0) {

        setTransactionHistoryText(props.starPoints.DATA.transactions[0].transactionTypeText);

      } else {
        setShowLastTrans(false);
      }
    }

    logViewHomePage();
  }, [props.starPoints]);

  useEffect(() => {

    if (props.tierPoints) {

      if (props.tierPoints.STATUS === 'SUCCESS' && props.tierPoints.DATA.connectionStatus && props.tierPoints.DATA.connectionStatus !== "D") {
        setIsTierPointsLoading(false);
        let loyaltyType = props.tierPoints.DATA.loyaltyType;
        setIsTierPointsAvailable(true);

        if (props.tierPoints.DATA.connectionStatus !== "T") {
          sessionStorage.setItem("tierLevel", loyaltyType);
          props.getCxOffersAction();
        } else {
          setIsOfferAvailable(false);
        }

        switch (loyaltyType) {

          case process.env.REACT_APP_THREE_STAR: case process.env.REACT_APP_THREE_STAR_S:
            setTierLevelIcon(threeStarLogo);
            break;
          case process.env.REACT_APP_FIVE_STAR: case process.env.REACT_APP_FIVE_STAR_S:
            setTierLevelIcon(fiveStarLogo);
            break;
          case process.env.REACT_APP_SEVEN_STAR: case process.env.REACT_APP_SEVEN_STAR_S:
            setTierLevelIcon(sevenStarLogo);
            break;
          case process.env.REACT_APP_LOYALTY_TYPE_NOT_DEFINED:
            setTierLevelIcon(starWhite);
            break;
          default:
            setIsTierPointsAvailable(false);
            return;
        }

      }

      if (props.tierPoints.STATUS === 'SUCCESS' && props.tierPoints.DATA.connectionStatus && props.tierPoints.DATA.connectionStatus === "D") {
        setIsOfferAvailable(false);
        setIsTierPointsAvailable(false);
        setIsTierPointsLoading(false);
      }
    }
  }, [props.tierPoints]);

  useEffect(() => {

    if (props.cxOffers.STATUS) {

      if (props.cxOffers.STATUS === 'SUCCESS') {

        setPopularOffers([]);

        if (props.cxOffers.DATA.carecategory_list) {
          sessionStorage.setItem("offer_referrence", props.cxOffers.DATA.reference);
          props.cxOffers.DATA.carecategory_list.map((category) => {
            category.offer_list.map((offer) => {
              if (offer.is_popular_offer === "yes") {
                setPopularOffers(prevItems => [...prevItems, offer]);
              }
            });
          });
        }

        setIsOfferLoaded(true);
        checkAndLanuchStarOffers();
      }

      if (props.cxOffers.STATUS === 'FAILED') {
        setIsOfferAvailable(false);
      }

      if (isTierPointsAvailable) {
        checkAndLanuchStarLoyalty();
      }

    }
  }, [props.cxOffers]);

  const checkAndLanuchStarLoyalty = () => {

    if (sessionStorage.getItem("pageName") && sessionStorage.getItem("pageName") === "STAR_REWARDS") {
      sessionStorage.setItem("pageName", null);
      sessionStorage.setItem("key", null);
      history.push('/star-loyalty');
    }
  }

  const checkAndLanuchStarOffers = () => {

    if (sessionStorage.getItem("pageName") && sessionStorage.getItem("pageName") === "OFFER_DETAIL") {

      let key = sessionStorage.getItem('key');

      sessionStorage.setItem("pageName", null);
      sessionStorage.setItem("key", null);

      if (props.cxOffers.DATA.carecategory_list) {
        props.cxOffers.DATA.carecategory_list.map((category) => {
          category.offer_list.map((offer) => {
            if (offer.offer_id === key) {
              history.push({
                pathname: '/offer-detail',
                state: { offer: offer, isFrom: 'CATEGORY' }
              })
            }
          });
        });
      }
    }

    if (sessionStorage.getItem("pageName") && sessionStorage.getItem("pageName") === "CATEGORY") {

      let key = sessionStorage.getItem('key');

      sessionStorage.setItem("pageName", null);
      sessionStorage.setItem("key", null);

      if (props.cxOffers.DATA.carecategory_list) {
        props.cxOffers.DATA.carecategory_list.map((category, index) => {
          if (category.category_id === key) {
            sessionStorage.setItem("CATEGORY-INDEX", index.toString());
            history.push('/offers');
          }
        });
      }
    }

  }

  return (
    <Fragment>
      {isLoading ? (
        <div style={loadingStyles}>
          <CircularLoading />
        </div>
      ) : (
        <Layout
          leftIcon={ArrowLeftIcon}
          leftIconLink="exitWebView"
        >

          <BodyContainer>
            <PullToRefresh onRefresh={() => history.push('/')}
              refreshingContent=" "
              pullingContent=" "
            >
              <header className="App-container" style={{ backgroundImage: `url(${"https://d397hqg6fxi8os.cloudfront.net/home_background.gif"})` }}>
                <div style={{ width: "100%" }}>

                  <div className="row" style={{ marginLeft: '15px' }}>
                    <div className="col no-padding" style={{ marginLeft: '-15px', marginTop: '20px' }}>
                      <img
                        src={starPoints}
                        className="App-list-icon main-icon"
                        alt="logo"
                      />
                    </div>
                    <div className="col no-padding" style={{ alignItems: 'right', marginRight: '-150px' }}>
                      <img
                        src="https://d397hqg6fxi8os.cloudfront.net/right_logo.gif"
                        className="Dashboard-icon main-icon"
                        alt="logo"
                      />
                    </div>
                  </div>


                  {
                    isMobileNumberHidden ? null :
                      <div className="row no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px', marginTop: '15px' }}>
                        <div className="col-1 no-padding">
                          <div className="img-submit align-left">
                            <img src={userIcon} style={{ width: '36px', height: '36px' }} />
                          </div>
                        </div>
                        <div className="col-10 no-padding">
                          <div className="color-gray align-left" style={{ fontSize: '15px', fontWeight: 'bold', display: 'flex', maxLines: '1', marginLeft: '10px' }} >
                            {sessionStorage.getItem("mobileNumber")}
                          </div>
                        </div>

                      </div>}

                  {
                    isStarPointsLoading ? <div className="card card-3" style={{ backgroundColor: '#d3ded6', height: '210px' }}>
                      <div className="row no-padding" style={{ marginLeft: '15px' }}>
                        <div className="col">
                          <div className="row align-left no-padding">
                            <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', height: '12px', width: '80%' }}></div>
                          </div>
                          <div className="row no-padding">
                            <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', height: '40px', width: '50%', marginTop: '15px' }}></div>
                          </div>
                        </div>
                      </div>
                      <hr className="hr-white" style={{ backgroundColor: '#c1c9c3', marginTop: '20px' }}></hr>
                      <div className="row no-padding" style={{ marginLeft: '15px' }}>
                        <div className="col">
                          <div className="row align-left no-padding">
                            <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', height: '12px', width: '80%' }}></div>
                          </div>
                          <div className="row align-left no-padding">
                            <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', height: '25px', width: '30%', marginTop: '25px' }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                      : <div className="card-purple card-3">
                        <div className="row no-padding">
                          <div className="col-8">
                            <div className="row align-left no-padding">
                              <h5 className="font-medium color-white font-size-12 padding-left">
                                Star Points - Redeemable Points
                              </h5>
                            </div>
                            <div className="row no-padding">
                              <h1 className="font-medium color-white">
                                <span className="font-size-23 padding-left">
                                  {numberWithCommas(redeemablePoints)}
                                </span>
                                <span className="font-size-14">.{redeemablePointsDecimal}</span>
                              </h1>
                            </div>
                          </div>
                        </div>
                        <hr className="hr-white"></hr>
                        <div className="row no-padding">
                          <div className="col-4 align-left">
                            <h5 className="font-medium color-white font-size-10">Total Points</h5>
                          </div>
                          <div className="col-8 align-right">
                            <h5 className="font-medium color-white font-size-10">
                              {expireDate ? "Expires on " + moment(expireDate).format("DD MMMM YYYY") : ""}
                            </h5>
                          </div>
                          <div></div>
                        </div>
                        <div className="row no-padding">
                          <div className="col-4 align-left">
                            <div className="row no-padding">
                              <h2 className="color-white">
                                <span className="font-medium font-size-20 padding-left">
                                  {numberWithCommas(totalPoints)}
                                </span>
                                <span className="font-medium font-size-12">{`.${totalPointsDecimal}${totalPointsDecimal === 0 ? 0 : ""
                                  }`}</span>
                              </h2>
                            </div>
                          </div>
                          <div className="col-8 align-right">
                            {numberWithCommas(pointsExpire) ? (
                              <h2 className=" color-white">
                                <span style={{ marginRight: "5px" }}>
                                  {/*<img src={info} className="info-icon" alt="logo" />*/}
                                </span>
                                <span className="font-medium font-size-20">
                                  {numberWithCommas(pointsExpire) === "0" ? "" : numberWithCommas(pointsExpire)}
                                </span>
                                <span className="font-medium font-size-12">
                                  {numberWithCommas(pointsExpireDecimal) === "00" || numberWithCommas(pointsExpireDecimal) === "0" ? "" : "."}
                                  {numberWithCommas(pointsExpireDecimal) === "00" || numberWithCommas(pointsExpireDecimal) === "0" ? "" : pointsExpireDecimal}
                                </span>
                              </h2>
                            ) : (
                              <h2></h2>
                            )}
                          </div>
                        </div>
                      </div>
                  }

                  {
                    showLastTrans && !isStarPointsLoading ?
                      <div className="card light-green-box no-padding">
                        <div className="row no-padding" style={{ marginLeft: "15px" }}>
                          <div className="col-9 no-padding">
                            <h4 className=" color-black">
                              <span className="font-bold-1 font-size-14">{transactionHistoryText}</span>
                            </h4>
                          </div>
                          <div className="col-3 no-padding">
                            <h4 className="font-blue">
                              <span className="font-bold-1 font-size-14">
                                <a onClick={handleViewRecentTransactionClick} >VIEW</a>
                              </span>
                            </h4>
                          </div>
                        </div>
                      </div> : null
                  }

                  {/* Feature Cards */}

                  {/*<Link to="/Star-loyality">*/}
                  {
                    isDialogNumber && isTierPointsLoading ? <div className="card card-3" style={{ backgroundColor: '#d3ded6', height: '100px' }}>
                      <div className="row no-padding">
                        <div className="col-3 no-padding">
                          <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', marginLeft: '10px', height: '60px', width: '60px', borderRadius: '15px' }}></div>
                        </div>
                        <div className="col-6 no-padding">
                          <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', height: '12px', width: '100%' }}></div>
                          <div className="row no-padding" style={{ backgroundColor: '#c1c9c3', height: '12px', width: '80%', marginTop: '10px' }}></div>
                        </div>
                      </div>
                    </div>
                      :
                      isTierPointsAvailable ? <a style={{ color: 'white' }} onClick={handleLoyaltyClick}>
                        <div className="card card-3" style={{ backgroundColor: '#afdc7e' }}>
                          <div className="row no-padding">
                            <div className="col-3 no-padding">
                              <img src={tierLevelIcon} className="App-list-icon align" alt="logo" />
                            </div>
                            <div className="col-6 no-padding">
                              <div className="row no-padding">
                                <h2 className="color-black font-size-14">Dialog Star Rewards</h2>
                              </div>
                              <div className="row no-padding">
                                <h4 className="color-gray font-size-12">
                                  Check your Star Reward Points</h4>
                              </div>
                            </div>
                            <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                            </div>
                          </div>
                        </div>
                      </a> : null}

                  {isOfferLoaded && popularOffers.length > 0 ? <div className="popular-offer-container">
                    {popularOffers.map((item, index) => (
                      <a key={index} onClick={(e) => handleOferClick(e, item)}>
                        <div className="popular-offer-card">
                          <div className="popular-offer-card_thumb">
                            <img
                              src={item.offer_banner}
                              alt="Logo"
                              className="popular-offer-card_thumb"
                            />
                          </div>
                          <div className="popular-offer-card_content">
                            <div className="popular-offer-card_content__title">{item.offer_title}</div>

                            <div className="popular-offer-card_content__button">
                              <div>View Offer</div>
                            </div>
                          </div>
                        </div>
                      </a>

                    ))
                    }
                  </div> : null}

                  {isOfferAvailable ? (isOfferLoaded ? <a onClick={handleOffersClick}>
                    <div className="card card-3 ">
                      <div className="row no-padding" >
                        <div className="col-3 no-padding">
                          <img src={offers} className="App-list-icon" alt="logo" />
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                          <div className="row no-padding" style={{ marginLeft: '-30px' }}>
                            <h2 className={"color-black"} style={{ fontSize: '14px' }}>
                              Offers
                            </h2>
                          </div>
                          <div className="row no-padding"></div>
                        </div>
                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                        </div>
                      </div>
                    </div>
                  </a> : <div className="card card-3 ">
                    <div className="row no-padding" >
                      <div className="col-3 no-padding">
                        <img src={offersLoading} className="App-list-icon" alt="logo" style={{ borderRadius: 18 }} />
                      </div>
                      <div className="col-6 no-padding">
                        <div className="row no-padding">
                          <h2 className={isStarPointsLoading ? "color-gray" : "color-black"} style={{ fontSize: '14px' }}>
                            Offers
                          </h2>
                        </div>
                        <div className="row no-padding">
                          <h4 className="color-gray font-size-12">
                            Loading please wait......
                          </h4>
                        </div>
                        <div className="row no-padding"></div>
                      </div>
                      <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={ArrowRightIconGray} className="arrow-icon" alt="logo" />
                      </div>
                    </div>
                  </div>) : null}


                  {isDialogNumber ? <a style={{ color: 'white' }} onClick={handleSelfRedemptionClick}>
                    <div className="card card-3 ">
                      <div className="row no-padding">
                        <div className="col-3 no-padding">
                          <img src={redeemLogo} className="App-list-icon" alt="logo" />
                        </div>
                        <div className="col-6 no-padding">
                          <div className="row no-padding">
                            <h2 className={isStarPointsLoading ? "color-gray" : "color-black"} style={{ fontSize: '14px' }}>
                              Dialog bill payment / Reload
                            </h2>
                          </div>
                          <div className="row no-padding">
                            <h4 className="color-gray font-size-12">
                              Redeem Star Points to your connection number
                            </h4>
                          </div>
                          <div className="row no-padding"></div>
                        </div>
                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={isStarPointsLoading ? ArrowRightIconGray : ArrowRightIcon} className="arrow-icon" alt="logo" />
                        </div>
                      </div>
                    </div>
                  </a> : null}

                  {/*<Link to="/Star-points-donate">*/}
                  <a style={{ color: 'white' }} onClick={handlePointDonationClick}>
                    <div className="card card-3 ">
                      <div className="row no-padding">
                        <div className="col-3 no-padding">
                          <img src={donate} className="App-list-icon" alt="logo" />
                        </div>
                        <div className="col-6 no-padding">
                          <div className="row no-padding">
                            <h2 className={isStarPointsLoading ? "color-gray" : "color-black"} style={{ fontSize: '14px' }}>
                              Donate Points
                            </h2>
                          </div>
                          <div className="row no-padding">
                            <h4 className="color-gray font-size-12">
                              Donate your Points to a charity
                            </h4>
                          </div>
                          <div className="row no-padding"></div>
                        </div>
                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={isStarPointsLoading ? ArrowRightIconGray : ArrowRightIcon} className="arrow-icon" alt="logo" />
                        </div>
                      </div>
                    </div>
                  </a>
                  {/*</Link>*/}

                  {<a style={{ color: 'white' }} onClick={handlePointTransferClick}>
                    <div className="card card-3 ">
                      <div className="row no-padding">
                        <div className="col-3 no-padding">
                          <img src={transfer} className="App-list-icon" alt="logo" />
                        </div>
                        <div className="col-6 no-padding">
                          <div className="row no-padding">
                            <h2 className={isStarPointsLoading ? "color-gray" : "color-black"} style={{ fontSize: '14px' }}>
                              Transfer Points
                            </h2>
                          </div>
                          <div className="row no-padding">
                            <h4 className="color-gray font-size-12">
                              Transfer within your accounts
                            </h4>
                          </div>
                          <div className="row no-padding"></div>
                        </div>
                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={isStarPointsLoading ? ArrowRightIconGray : ArrowRightIcon} className="arrow-icon" alt="logo" />
                        </div>
                      </div>
                    </div>
                  </a>}

                  <div className="card card-3 ">
                    <div className="row no-padding">
                      <div className="col-3 no-padding ">
                        <img src={myqr} className="App-list-icon" alt="logo" />
                      </div>
                      <div className="col-6 no-padding">
                        <div className="row no-padding">
                          <h2 className="color-black font-size-14">My QR</h2>
                        </div>
                        <div className="row no-padding">
                          <h4 className="color-gray font-size-12">
                            Coming Soon!
                          </h4>
                        </div>
                        <div className="row no-padding"></div>
                      </div>
                      <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                      </div>
                    </div>
                  </div>

                  <Link to="/terms" >
                    <div className="card card-3 ">
                      <div className="row no-padding">
                        <div className="col-3 no-padding ">
                          <img src={termsandcon} className="App-list-icon" alt="logo" />
                        </div>
                        <div className="col-6 no-padding">
                          <div className="row no-padding">
                            <h2 className="color-black font-size-14">View terms & conditions</h2>
                          </div>
                          <div className="row no-padding">
                            <h4 className="color-gray font-size-12">
                              User terms of service
                            </h4>
                          </div>
                          <div className="row no-padding"></div>
                        </div>
                        <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                        </div>
                      </div>
                    </div>
                  </Link>

                  <div style={{ height: '85px' }}></div>

                </div>

              </header>
            </PullToRefresh>
          </BodyContainer>


          <Footer>
            <div className="row no-padding" style={{ position: 'sticky', bottom: 0, left: 0, right: 0 }}>
              <div className="col-12">
                <a style={{ color: 'white' }} onClick={handleViewTransactionClick}>
                  <button className="btn-transaction remove-outline">
                    VIEW TRANSACTIONS
                  </button>
                </a>
              </div>
            </div>
          </Footer>
        </Layout>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMobileNumberAction: () => dispatch(getMobileNumberAction()),
    fetchStarpointsAction: () => dispatch(fetchStarpointsAction()),
    fetchTierPointsAction: () => dispatch(fetchTierPointsAction()),
    getCxOffersAction: () => dispatch(getCxOffersAction()),
    getConnectionUnderNicAction: () => dispatch(getConnectionUnderNicAction()),
    getLobForConnectionAction: () => dispatch(getLobForConnectionAction()),
  };
};

const mapStateToProps = (response) => {
  return {
    starPoints: response.StarPointReducer.starPoints,
    mobileNumber: response.MobileNumberReducer.mobileNumber,
    tierPoints: response.TierPointsReducer.tierPoints,
    cxOffers: response.OffersReducer.cxOffers,
    connections: response.MobileNumberReducer.connections,
    lob: response.MobileNumberReducer.lob
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarPointsHome);
