import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import threeStar from "../../../../../Resources/starLoyalty/three_star.svg";
import fiveStar from "../../../../../Resources/starLoyalty/five_star.svg";
import sevenStar from "../../../../../Resources/starLoyalty/seven_star.svg";
import ArrowLeftIcon from "../../../../../Resources/shared/arrow_left.svg";
import starTierOffer from "../../../../../Resources/starLoyalty/loyalty_offers.svg";
import ArrowRightIcon from "../../../../../Resources/shared/arrow_right_purple.svg";
import Layout from "../../../../Layout/Layout";
import "./../../../../../Common.scss";
import BodyContainer from "../../../../UI/BodyArea/Body";

const StarLoyaltyInfo = (props) => {

    const [isBenefitsViewExpanded, setIsBenefitsViewExpanded] = useState(false);
    const [termsButtonText, setTermsButtonText] = useState("SHOW MORE");
    const [loyaltyTierRanges, setLoyaltyTierRanges] = useState({});
    const [isStarRewardsOffersAvailable, setIsStarRewardsOffersAvailable] = useState(true);

    let history = useHistory();

    const handleTierOffersClicked = () => {
        sessionStorage.setItem("STAR-TIER-OFFERS-PATH", "STAR-LOYALTY-INFO");
        history.push("/offer-categories")
    }

    const handleTermsClicked = () => {
        setIsBenefitsViewExpanded(!isBenefitsViewExpanded);
        setTermsButtonText(isBenefitsViewExpanded ? "SHOW MORE" : "SHOW LESS")
    }

    useEffect(() => {

        var loyaltyTierRangesInArray = {};

        for (let i = 0; i < props.tierPoints.DATA.loyaltyTierRanges.length; i++) {
            let range = props.tierPoints.DATA.loyaltyTierRanges[i];
            loyaltyTierRangesInArray[range.loyaltyType] = range.loyaltyFloorValue;
        }

        setLoyaltyTierRanges(loyaltyTierRangesInArray);

        if (props.location.state && props.location.state.showTerms) {
            setIsBenefitsViewExpanded(true);
            setTermsButtonText("SHOW LESS")
            setTimeout(() => {
                window.scrollTo(0, 650);
            }, 250)
        }

        let loyaltyType = props.tierPoints.DATA.loyaltyType;

        if (loyaltyType === process.env.REACT_APP_LOYALTY_TYPE_NOT_DEFINED || !props.cxOffers.STATUS) {
            setIsStarRewardsOffersAvailable(false);
        }

    }, [props.tierPoints]);

    useEffect(() => {

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        }

    }, []);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        history.push("/star-loyalty")
    }

    return (
        <Fragment>
            <Layout
                leftIcon={ArrowLeftIcon}
                headerTitle="Star Rewards info"
                leftIconLink="/star-loyalty"
            >

                <BodyContainer>
                    <header className="App-container">
                        <div style={{ width: "100%" }}>

                            <div className="flex-container">
                                <div className="row" style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <div className="color-light-black" style={{ fontSize: '20px', fontWeight: 'medium' }} >Dialog Star Rewards</div>
                                    <div className="color-light-black" style={{ fontSize: '16px', fontWeight: 'medium', marginTop: '1rem' }} >
                                        Welcome to a world of exclusivity & privilege with Dialog Star Rewards! As a member, you will enjoy the best loyalty benefits throughout the year while being a part of the expansive Dialog network, along with its island-wide partner merchants. Reap a range of rewards throughout the year with our carefully selected offers and personalized discounts.
                                    </div>
                                </div>
                                <hr className="hr-dashed" style={{ marginTop: '0.3rem' }}></hr>

                                <div>
                                    <div className="row no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="col-2 no-padding">
                                            <div className="img-submit align-right">
                                                <img src={threeStar} style={{ width: '76px', height: '40px' }} />
                                            </div>
                                        </div>
                                        <div className="col-6 no-padding">
                                            <div className="color-light-black align-left" style={{ fontSize: '15px', fontWeight: 'medium', display: 'flex', maxLines: '1', marginLeft: '10px' }} >
                                                3 Star - {loyaltyTierRanges[process.env.REACT_APP_THREE_STAR || process.env.REACT_APP_THREE_STAR_S]} Tier Points
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1.5rem' }}>
                                        <div className="col-2 no-padding">
                                            <div className="img-submit align-right">
                                                <img src={fiveStar} style={{ width: '76px', height: '40px' }} />
                                            </div>
                                        </div>
                                        <div className="col-6 no-padding">
                                            <div className="color-light-black align-left" style={{ fontSize: '15px', fontWeight: 'medium', display: 'flex', maxLines: '1', marginLeft: '10px' }} >
                                                5 Star - {loyaltyTierRanges[process.env.REACT_APP_FIVE_STAR || process.env.REACT_APP_FIVE_STAR_S]} Tier Points
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1.5rem' }}>
                                        <div className="col-2 no-padding">
                                            <div className="img-submit align-right">
                                                <img src={sevenStar} style={{ width: '76px', height: '40px' }} />
                                            </div>
                                        </div>
                                        <div className="col-6 no-padding">
                                            <div className="color-light-black align-left" style={{ fontSize: '15px', fontWeight: 'medium', display: 'flex', maxLines: '1', marginLeft: '10px' }} >
                                                7 Star - {loyaltyTierRanges[process.env.REACT_APP_SEVEN_STAR || process.env.REACT_APP_SEVEN_STAR_S]} Tier Points
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {isStarRewardsOffersAvailable ? <a style={{ color: 'white', marginTop: '10px' }} onClick={handleTierOffersClicked}>
                                    <div className="card card-3 ">
                                        <div className="row no-padding">
                                            <div className="col-3 no-padding">
                                                <img src={starTierOffer} className="App-list-icon" alt="logo" />
                                            </div>
                                            <div className="col-6" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                <div className="row no-padding" style={{ marginLeft: '-25px' }}>
                                                    <h2 className={"color-black"} style={{ fontSize: '14px' }}>
                                                        Star Reward offers
                                                    </h2>
                                                </div>
                                                <div className="row no-padding"></div>
                                            </div>
                                            <div className="col no-padding" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={ArrowRightIcon} className="arrow-icon" alt="logo" />
                                            </div>
                                        </div>
                                    </div>
                                </a> : null}

                                <div className="card card-3">
                                    <div className="flex-container">
                                        <div className="color-light-black" style={{ fontSize: '18px', fontWeight: 'medium' }} >Terms & Conditions</div>
                                        <div className="color-light-black" style={{ fontSize: '15px', fontWeight: 'medium' }} >
                                            {isBenefitsViewExpanded ? <div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>All Dialog Axiata PLC’s (“Dialog”) Prepaid & Postpaid Customers (“Customer”, “you” or “your”) are eligible to earn Tier Points based on the reloads/monthly bill payments made to Dialog and for the number of months the Customer has been with the Dialog network.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Tier Points earned from the 1st of December in a particular year to 30th November of the next year shall determine your Dialog Star Rewards Loyalty Status (i.e. 3 Star, 5 Star, 7 Star) for the following year. As an example only; Points earned from 1st December 2020 to 30th November 2021 shall determine the Dialog Star Rewards Loyalty category for the year 2022.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>As a loyal customer of Dialog, 100 points for each completed month on the network shall be earned on the connection with the longest stay on the Dialog network.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>These Tier Points are added monthly and could be checked through the MyDialog App, Genie App, monthly bill or by dialing #107#.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>The total due amount as of 30th November of the time period considered for the Tier Points calculation needs to be settled in full, on or before the 30th of November of that particular year, in order to be eligible for the Dialog Star Rewards Loyalty Status.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Any outstanding debt to Dialog from permanently disconnected services shall invalidate your eligibility unless all outstanding amounts are fully cleared by 30th November of the year considered for Tier Points calculation.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Timely settlement of bills and good credit behaviour shall be considered for eligibility of membership.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Monthly payments for any add-to-bill schemes, doctor’s appointments, bus/movie ticket purchases, easy payment device schemes and total taxes charged to your bill shall not be considered in your eligibility to earn Tier Points.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog reserves the right to determine what components of the paid amounts shall qualify to earn these points.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Tier Points cannot be transferred to another Customer or shared in full or partially.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog does not have a scheme where additional points can be purchased, i.e., points of a third party cannot be purchased or added to your account.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog Star Reward Loyalty membership shall be offered to the contractual/legally registered owner of the connection and not to a third-party member.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog Star Rewards Loyalty discounts offered cannot be transferred or carried forward to the following year. All discounts have to be fully utilised before 31st December.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Membership for Dialog Star Rewards Loyalty shall be offered at the discretion of Dialog and Dialog retains the right to downgrade, revise or discontinue membership at any time without informing the Customer.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Customers shall be evaluated quarterly, and whoever accumulated 6,000 Tier Points (within the current year) shall be offered a 3 Star Reward Membership.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Quarterly tagging shall only be considered for the Customers who are not tagged as any Dialog Star Rewards Loyalty Customer in a previous quarter.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Customers shall adhere to the policies, mandatory provisions and guidelines relating to Anti Bribery and Corruption located at https://www.dialog.lk which may be amended from time to time.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog shall process any personal data the Customer provides in accordance with the Privacy Notice located at https://www.dialog.lk/privacy-notice</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>Dialog shall be entitled from time to time to amend and vary the terms and conditions mentioned herein.</div>
                                                <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                    <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>In the event of any inconsistency/dispute between the English terms and conditions and the Sinhala / Tamil terms and conditions, the English translation shall prevail to the extent of the conflict.</div>
                                            </div> :
                                                <div>
                                                    <div className="color-light-black" style={{ fontSize: '14px', fontWeight: 'medium' }} >
                                                        <span style={{ fontWeight: 'bold', fontSize: '22px' }}>&#8226; </span>All Dialog Axiata PLC’s (“Dialog”) Prepaid & Postpaid Customers.....</div>
                                                </div>
                                            }
                                        </div>
                                        <h4 className="font-blue" style={{ marginTop: '5px' }}>
                                            <span className="font-medium font-size-12">
                                                <a onClick={handleTermsClicked}>{termsButtonText}</a>
                                            </span>
                                        </h4>
                                    </div>
                                </div>

                            </div>

                            <div style={{ height: '85px' }}></div>

                        </div>

                    </header>
                </BodyContainer>
            </Layout>
        </Fragment>
    );
};

const mapStateToProps = (response) => {
    return {
        tierPoints: response.TierPointsReducer.tierPoints,
        cxOffers: response.OffersReducer.cxOffers
    };
};

export default connect(mapStateToProps)(StarLoyaltyInfo);

